import React, { useState, useEffect } from "react";
import {
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { IoIosArrowDown } from "react-icons/io";
import {
  Link,
  NavLink,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom";
import { Images } from "img";
import { UserService } from "utility/services";
import { AuthHook } from "context/auth";
import { CashoutPopup } from "components/CashoutPopup";
import { ImageWithFallback } from "components/FallbackImage";

const NavBar = () => {
  const history = useHistory();
  const location = useLocation();

  const { logout: logoutContext, user, login } = AuthHook();
  const [showModal, setShowModal] = useState(false);
  const [hideMenu, setHideMenu] = useState(true);
  // const allowedCashOut = useMemo(() => (user?.authorities || []).includes(Roles.cashout),[user])

  useEffect(() => {
    const pathName = location.pathname;
    setHideMenu(
      [
        "play",
        "login",
        "register",
        "forgot-password",
        "reset-password",
        "register-activation",
      ].some((x) => pathName.includes(x))
    );
  }, [location.pathname]);
  const logoutClick = () => {
    UserService.logout();
    logoutContext();
    history.push("/login");
  };

  const cashoutSucceed = (amount) => {
    const body = {
      ...user,
      balance: user.balance - amount,
    };
    login(body);
    UserService.storeUser(body);
    setShowModal(false);
  };

  return (
    <>
      {!hideMenu && (
        <div className="header-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-2 col-6 order-1">
                <div className="header-logo-container">
                  <a href="https://vuele.io/" target="_blank">
                    <img src={Images.logo.big} width="148" alt="Logo" />
                  </a>
                </div>
              </div>

              <div className="col-lg-8 col-12 order-3 order-lg-2">
                <div className="navigation-bar">
                  <div className="nav-item">
                    <Link to="/">Releases</Link>
                  </div>
                  {UserService.isAuthenticated() && (
                    <>
                      <div className="nav-item">
                        <Link to="/app/my-nfts">My Collection</Link>
                      </div>

                      <div className="nav-item">
                        <Link to="/app/marketplace">Marketplace</Link>
                      </div>
                    </>
                  )}

                  <div className="nav-item">
                    <a target="_blank" href="https://vuele.io/faqs/">
                      FAQs
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-6 order-2 order-lg-3">
                <div className="user-profile-container">
                  <Nav navbar>
                    {/* {allowedCashOut && <NavItem className="cashout" onClick={() => setShowModal(true)}>
                  <img src={Images.cashout} alt="My Wallet" title="My Wallet" />
                </NavItem>} */}
                    {UserService.isAuthenticated() && (
                      <UncontrolledDropdown nav inNavbar className="text-left">
                        <DropdownToggle nav className="nav-link d-flex">
                          <ImageWithFallback
                            fallback={Images.user}
                            src={user?.imageUrl}
                            width="30"
                            height="30"
                            className="userpicture-style"
                            alt="user details"
                          />

                          <div className="d-flex flex-column">
                            <b className="d-flex mb-0">
                              <span className="username-style">
                                {user?.firstName} {user?.lastName}
                              </span>
                            </b>
                            <p className="useremail-style ">{user?.email}</p>
                          </div>

                          <div>
                            <IoIosArrowDown
                              color="#84c142"
                              className="user-arrow-icon"
                            />
                          </div>
                        </DropdownToggle>

                        <DropdownMenu className="user-dropdown-style" right>
                          <NavLink
                            className="dropdown-item"
                            to={`/app/edit-profile`}
                          >
                            Edit Profile
                          </NavLink>
                          {user?.hasPassword && (
                            <NavLink
                              className="dropdown-item"
                              to={`/app/change-password`}
                            >
                              Change Password
                            </NavLink>
                          )}

                          {/* <NavLink
                      to={`/app/transactions`}
                      className="dropdown-item"
                    >
                      Transactions
                    </NavLink> */}
                          <DropdownItem
                            className="dropdown-item"
                            onClick={logoutClick}
                          >
                            Log Out
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                    {!UserService.isAuthenticated() && (
                      <button
                        onClick={() => history.push("/login")}
                        className="btn btn-login btn-custom-form btn-block btn-lg"
                      >
                        Log In
                      </button>
                    )}
                  </Nav>
                </div>
              </div>
            </div>
          </div>
          <CashoutPopup
            showModal={showModal}
            toggle={() => setShowModal(false)}
            cashoutSucceed={cashoutSucceed}
          />
        </div>
      )}
    </>
  );
};

export default withRouter(NavBar);
