import React from 'react'
import { Images } from 'img';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    padding: 5rem 0;
    text-align: center;
    background: rgba(0,0,0, 0.3);
    
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(${Images.home});
        background-size: 100%;
        background-position: center center;
        z-index: -1;
        opacity: 0.65;
 }
`
const HeaderTitle = styled.h1`
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    text-transform: uppercase;
`

export const PageHeader = ({ title }) => (
    <div className='container-fluid px-0'>
        <div className='row no-gutters'>
            <div className='col-12'>
                <Container>
                    <HeaderTitle>{title}</HeaderTitle>
                </Container>
            </div>
        </div>
    </div>
)