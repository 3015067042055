import React, { useState } from "react";
import Document from "layout/Document";
import { Link } from "react-router-dom";
import { Images } from "img";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "elements/Inputs";
import { UserService } from "utility/services";
import { ErrorMessages } from "utility/constants/common";

const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
});

const ForgotPassword = () => {
  const {
    register: forgotPassword,
    errors,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (data) => {
    try {
      setSuccess(false);
      setLoading(true);
      await UserService.forgotPassword({ email: data.email });
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setError(ErrorMessages.badCredentials);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Document title="Forgot Password">
      <div>
        <div
          className="BackgoundContainer"
          style={{
            backgroundImage: `url(${Images.home})`,
          }}
        />

        <div className="login-container">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="login-box">
                  <div className="login-logo-container">
                    <img src={Images.logo.big} width="272" alt="Logo" />
                  </div>

                  <h2 className="signin-textstyle">Forgot Password</h2>

                  {!success ? (
                    <form
                      name="formLogin"
                      noValidate
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="mb-3">
                        <TextField
                          ref={forgotPassword}
                          errors={errors?.email}
                          placeholder="Email Address"
                          name="email"
                        />
                      </div>

                      {error && <div className="text-danger mb-2">{error}</div>}

                      <button
                        type="submit"
                        disabled={loading}
                        className="btn btn-custom-form btn-block btn-lg"
                      >
                        {loading ? "Loading..." : "Send Email"}
                      </button>
                    </form>
                  ) : (
                    <p className="register-text-style mt-3">
                      Email has been sent to your email address, please check
                      your inbox to reset your password.
                    </p>
                  )}
                  <h4 className="register-text-style mt-4">
                    <Link to="/login" className="forgot-password-style">
                      Back to Login
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Document>
  );
};

export default ForgotPassword;
