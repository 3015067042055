import React from 'react'
import Document from 'layout/Document'
import { Images } from 'img';

const TermsOfUse = () => {

    return (
        <Document title='Login Administrator' >
            <div>
                <div
                    className="BackgoundContainer"
                    style={{
                        backgroundImage: `url(${Images.home})`
                    }} />

                <div className="login-container">
                    <div className='container'>

                        <div className='row justify-content-center'>
                            <div className='col-12'>

                                <div className="login-box text-white">

                                    <div className='login-logo-container'>
                                        <img src={Images.logo.big} width='272' alt='Logo' />
                                    </div>

                                    <h2 className='signin-textstyle'>Terms Of Use</h2>

                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </p>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        </Document >
    )
}

export default TermsOfUse
