import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthHook } from "context/auth";
import { UserService } from "utility/services";
import { MobileFormField, TextField } from "elements/Inputs";
import { ErrorMessages } from "utility/constants/common";
import { BiUser } from "react-icons/bi";
import UserIcon from "../../img/default-user-pic.png";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { useHistory } from "react-router-dom";
const profileSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string(),
  mobileNumber: yup
    .string()
    .trim()
    .required("Mobile number is required")
    .test("invalid", "Invalid Mobile Number", (value) => {
      const valid = isValidPhoneNumber(value) && isPossiblePhoneNumber(value);
      return valid;
    }),
});

export const EditProfile = () => {
  const history = useHistory();
  const {
    register: editProfile,
    errors,
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(profileSchema),
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [profileImage, setProfileImage] = useState("");

  const { user, login } = AuthHook();

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [user]);
  useEffect(() => {
    setProfileImage(user?.imageUrl);
  }, [user]);
  const save = async (data) => {
    try {
      setError("");
      setSuccess("");
      setLoading(true);

      const body = {
        ...user,
        ...data,
      };
      await UserService.add(body);
      login(body);
      setSuccess("Data saved successfully.");
    } catch (err) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="payment">
      <div className="header-background">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="popup">
                <button onClick={() => history.goBack()}>X</button>
              </div>
              <div className="popup-box">
                <form noValidate onSubmit={handleSubmit(save)}>
                  <label className="popup-title pb-4">
                    <BiUser
                      color="#d6d2bc"
                      style={{ fontSize: "1.25rem", marginRight: 15 }}
                    />
                    Edit Profile Details
                  </label>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TextField
                          ref={editProfile}
                          errors={errors?.firstName}
                          placeholder="First Name"
                          name="firstName"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TextField
                          ref={editProfile}
                          errors={errors?.lastName}
                          placeholder="Last Name"
                          name="lastName"
                        />
                      </div>
                    </div>
                  </div>
                  {/* {user?.hasPassword && (
                    <div className="form-group">
                      <TextField
                        ref={editProfile}
                        placeholder="Email Address"
                        name="email"
                      />
                    </div>
                  )} */}

                  <div className="form-group">
                    <MobileFormField
                      control={control}
                      errors={errors?.mobileNumber}
                      placeholder="Mobile Number"
                      name="mobileNumber"
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn btn-next w-100"
                    >
                      {loading ? "Saving..." : "SAVE CHANGES"}
                    </button>
                  </div>

                  {success && <label className="text-white">{success}</label>}
                  {error && <label className="text-danger">{error}</label>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
