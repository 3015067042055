import React, { useEffect, useState } from 'react'
import Document from 'layout/Document'
import { Link, useLocation } from 'react-router-dom'
import { Images } from 'img';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from 'elements/Inputs'
import { UserService } from 'utility/services'
import { ErrorMessages } from 'utility/constants/common'
import { BsEye, BsEyeSlash } from 'react-icons/bs'

const resetPasswordSchema = yup.object().shape({
	password: yup.string().required("Password is required")
		.min(4, "Password should be must be between 4 and 100")
		.max(100, "Password should be must be between 4 and 100"),
	confirmPassword: yup.string().required("Confirm Password is required").oneOf([yup.ref('password'), null], 'Passwords must match')
});

const ResetPassword = () => {

	let { search } = useLocation();

	const {
		register: resetPassword,
		errors,
		handleSubmit,
	} = useForm({
		resolver: yupResolver(resetPasswordSchema)
	});
	const [success, setSuccess] = useState(false);
	const [key, setKey] = useState('');
	const [keyError, setKeyError] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showCPassword, setShowCPassword] = useState(false);


	useEffect(() => {
		const key = new URLSearchParams(search).get("token");
		if (key) {
			setKey(key)
		} else {
			setKeyError("Invalid Link, Please contact administrator");
		}
	}, [search]);

	const onSubmit = async (data) => {
		try {
			setSuccess(false);
			setLoading(true);
			await UserService.resetPassword({
				key,
				"newPassword": data.password
			});
			setSuccess(true);
		} catch (err) {
			console.log(err);
			setError(ErrorMessages.badCredentials);
		} finally {
			setLoading(false);
		}
	}

	return (
		<Document title='Forgot Password' >
			<div>
				<div
					className="BackgoundContainer"
					style={{
						backgroundImage: `url(${Images.home})`
					}} />

				<div className="login-container">
					<div className='container'>

						<div className='row justify-content-center'>
							<div className='col-12'>

								<div className="login-box">

									<div className='login-logo-container'>
										<img src={Images.logo.big} width='272' alt='Logo' />
									</div>

									<h2 className='signin-textstyle'>Reset Password</h2>

									{!keyError && <>{!success ? <form name='formLogin' noValidate onSubmit={handleSubmit(onSubmit)}>

										<div className='input-group mb-3'>
											<TextField
												type={showPassword ? 'text' : 'password'}
												ref={resetPassword}
												errors={errors?.password}
												placeholder="Password"
												name="password"
											/>
											<div className='show-password-toggle'>
												<span
													className='input-group-text'
													onClick={e => {
														setShowPassword(!showPassword);
													}}
												>
													{showPassword ? <BsEye /> : <BsEyeSlash />}
												</span>
											</div>
										</div>
										<div className='input-group mb-3'>
											<TextField
												type={showCPassword ? 'text' : 'password'}
												ref={resetPassword}
												errors={errors?.confirmPassword}
												placeholder="Confirm Password"
												name="confirmPassword"
											/>
											<div className='show-password-toggle'>
												<span
													className='input-group-text'
													onClick={e => {
														setShowCPassword(!showCPassword);
													}}
												>
													{showCPassword ? <BsEye /> : <BsEyeSlash />}
												</span>
											</div>
										</div>

										{error && <div className="text-danger mb-2">
											{error}
										</div>}

										<button
											type="submit"
											disabled={loading}
											className='btn btn-custom-form btn-block btn-lg'
										>
											{loading ? 'Loading...' : 'Confirm'}
										</button>

									</form> :
										<p className='register-text-style mt-3'>
											Password Reset successfully. Please login again to access the application.
										</p>} </>}
									{keyError && <p className='register-text-style mt-3'>
										{keyError}
									</p>}
									<h4 className='register-text-style mt-4'>
										<Link to='/login' className="forgot-password-style">Back to Login</Link>
									</h4>

								</div>
							</div>
						</div>
					</div>
				</div >
			</div>
		</Document >
	)
}

export default ResetPassword;