
import React from 'react'
import {
    Modal,ModalBody,
} from 'reactstrap'
import { IoIosInformationCircleOutline } from 'react-icons/io';

export const MessagePopup = ({ showModal,toggle,title,message }) => (
    <Modal isOpen={showModal} toggle={toggle} className="modal-dialog-centered  claim-modal claimed-modal"  >
        <ModalBody>
            <div>
                <p className="text-center">
                    <IoIosInformationCircleOutline
                        color='#DE9E00'
                        size='100px'
                    />
                </p>
                <p className='my-4 thank-you-text text-center'>
                    {title}
                </p>

                <p className='my-4 text-center description-text'>
                    {message}
                </p>

                <div className="d-flex justify-content-center">
                    <button type='button' className='btn btn-next  mr-2' onClick={toggle}>
                        Okay
                    </button>
                </div>

            </div>
        </ModalBody>
    </Modal>
)