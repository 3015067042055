export class BrowserUtility {
  static save = (key, value) => {
    window.localStorage.setItem(key, value);
  };

  static get = (key) => {
    return window.localStorage.getItem(key);
  };
  static getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  static saveObj = (key, obj) => {
    window.localStorage.setItem(key, JSON.stringify(obj));
  };

  static getObj = (key) => {
    const temp = window.localStorage.getItem(key);
    if (temp) {
      return JSON.parse(temp);
    }
    return null;
  };

  static remove = (key) => {
    window.localStorage.removeItem(key);
  };

  static removeAll = () => {
    window.localStorage.clear();
  };
}
