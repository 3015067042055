import React,{ useEffect,useState } from 'react'
import Document from 'layout/Document'
import { Link,useLocation } from 'react-router-dom'
import { Images } from 'img'
import { UserService } from 'utility/services'
import { ErrorMessages } from 'utility/constants/common'
import { LoadingBar } from 'components/LoadingBar'

const RegisterActivation = () => {

	let { search } = useLocation();

	const [success,setSuccess] = useState(false);
	const [error,setError] = useState('');
	const [loading,setLoading] = useState(false);

	const onSubmit = async (key) => {
		try {
			setError('');
			setLoading(true);
			await UserService.registerActivate(key);
			setSuccess(true);
		} catch (err) {
			setError(err?.error?.detail || ErrorMessages.default);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		const key = new URLSearchParams(search).get("key");
		if (key) {
			onSubmit(key)
		} else {
			setError("Invalid Link, Please contact administrator");
		}
	},[search]);

	return (
		<Document title='Register' className='login-page'>
			<div>

				<div
					className="BackgoundContainer"
					style={{
						backgroundImage: `url(${Images.home})`
					}} />

				<div className="login-container">
					<div className="container">

						<div className='row justify-content-center'>
							<div className='col-12'>

								<div className="login-box">

									<div className='login-logo-container'>
										<img src={Images.logo.big} width='272' alt='Logo' />
									</div>

									<h2 className='signin-textstyle mt-2'>Account Confirmation</h2>
									<div className="text-center">
										{loading ? <LoadingBar dark={true} /> : <>
											{success && <p className='register-text-style mt-3'>
												Your registration has been completed.
											</p>}
											{error && <p className='register-text-style mt-3'>
												{error}
											</p>}
											<Link to='/login' className='linkDefalut'>
												Back to login
											</Link>
										</>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Document>
	)
}

export default RegisterActivation;
