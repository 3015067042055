import moment from "moment";

export default class DateUtility {

    static formatDate = (date,format = "MM/DD/YYYY hh:mm A") => {
        if (!date) {
            return '';
        }
        return moment(date).format(format)
    };

    static getDays = () => Array.apply(null,Array(7)).map((_,i) =>
        moment(i,'e').startOf('week').isoWeekday(i + 1).format('dddd'));

    static addDays = (date,days) => moment(date).add(days,"days").toDate();

    static isDateAfter = (date1,date2) => moment(date1).isAfter(moment(date2));


}
