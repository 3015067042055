
import styled from 'styled-components';

export const PageContainer = styled.div`
    position: relative;
`

export const Title = styled.h1`
    font-weight: bold;
    font-size: 2.1875rem;
    line-height: 1.3;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 1.875rem;
`

export const Qty = styled.p`
    font-style: italic;
    font-weight: 500;
    font-size: 1.5625rem;
    line-height: 1.2;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin-bottom: 0.75rem;
`

export const Credits = styled.p`
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.55;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin-bottom: 1rem;
`

export const Description = styled.div`
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.55;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin-bottom: 3.4375rem;
    white-space: pre-line;
`

export const MediaType = styled.h2`
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 2.5;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
`

export const BackButton = styled.div`
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.55;

    display: flex;
    align-items: center;
    color: #FFFFFF;
    position: relative;
    padding-left: 1.75rem;

    margin: 2rem 0;
    cursor : pointer;

    &::before {
        border-style: solid;
        border-width: 4px 4px 0 0;
        border-color: #84C142;
        content: '';
        display: inline-block;
        height: 1.5rem;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 1.5rem;

        position: absolute;
        transform: rotate(-135deg);
        left: 0;
    }
`

export const MediaTypeIcon = styled.div`
    position: relative;
    background: #1A1A1A;
    border-radius: 100%;
    width: 92px;
    height: 92px;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 1.25rem;
`

export const DetailSection = styled.div`
    padding: 6.875rem 0;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.55;
    letter-spacing: 0.01em;
    color: #FFFFFF;

    @media (max-width: 991.98px) { 
        padding: 3rem 0;
    }

    @media (max-width: 575.98px) { 
        padding: 2rem 0;
    }

`

export const MediaAction = styled.div`
    display: flex;
    a, button {
        width: 100%;
        margin-right: 1.5rem;
        margin-top: 0 !important;

        &:last-child {
            margin-right: 0;
        }
    }

    .copy-button {
       flex: 0 0 50px;
    }

    @media (max-width: 575.98px) { 
        flex-direction: column;
  
        a, button {
            margin: 0.25rem 0 !important;
        }
      }

`

export const PosterImage = styled.div`
    padding-right: 1rem;
    height : 100%;

    img {
        max-width: 100%;
    }

    @media (max-width: 991.98px) { 
        padding-right: 0;

        margin-bottom: 1rem;
    }
`