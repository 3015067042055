import React, { forwardRef, useMemo, useState } from "react";
import { useParams } from "react-router";
import { CanSellHook, CardDetailsHook, CardHistoryHook } from "hooks/cards";
import { LoadingBar, OverlayLoadingBar } from "components/LoadingBar";
import {
  ImageCardDetails,
  StreamCardDetails,
  History,
  VideoCardDetails,
  TextCardDetails,
  IllustrationCardDetails,
} from "page-components/card-details";
import { AiFillBank } from "react-icons/ai";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, ModalBody } from "reactstrap";
import { IoIosInformationCircleOutline, IoLogoUsd } from "react-icons/io";
import { TextField } from "elements/Inputs";
import { AuthHook } from "context/auth";
import { CardAssetTypes, Roles, ErrorMessages } from "utility/constants/common";
import { CardsService, UserService } from "utility/services";
import { useHistory } from "react-router-dom";
import { MessagePopup } from "components/MessagePopup";
const sellingSchema = yup.object().shape({
  price: yup
    .number()
    .typeError("Selling Price is required")
    .required("Selling Price is required")
    .positive("Selling Price should be positive number")
    .min(0.5, "Selling price should not be less than $0.5."),
});

const USDIcon = {
  position: "absolute",
  right: 0,
  top: "32px",
};

const BankSetupModal = ({ showModal, toggle, confirmClick }) => (
  <Modal
    isOpen={showModal}
    toggle={toggle}
    className="modal-dialog-centered claim-modal claimed-modal"
  >
    <ModalBody style={{ padding: "0 1rem" }}>
      <div>
        <p className="text-center">
          <AiFillBank color="#DE9E00" size="100px" />
        </p>

        <p className="my-4 thank-you-text text-center">Bank Setup</p>

        <p className="my-4 text-center description-text">
          Need to setup bank before sell card
        </p>

        <div className="d-flex">
          <button
            type="button"
            className="btn btn-next w-100 mr-2"
            onClick={confirmClick}
          >
            Initiate Process
          </button>
          <button type="button" className="btn btn-next w-100" onClick={toggle}>
            No
          </button>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

const ConfirmCancelSell = ({ showModal, toggle, confirmClick }) => (
  <Modal
    isOpen={showModal}
    toggle={toggle}
    className="modal-dialog-centered claim-modal claimed-modal"
  >
    <ModalBody style={{ padding: "0 1rem" }}>
      <div>
        <p className="text-center">
          <IoIosInformationCircleOutline color="#DE9E00" size="100px" />
        </p>

        <p className="my-4 thank-you-text text-center">Card</p>

        <p className="my-4 text-center description-text">
          Are you sure you want to cancel sell this card?
        </p>

        <div className="d-flex">
          <button
            type="button"
            className="btn btn-next w-100 mr-2"
            onClick={confirmClick}
          >
            Yes
          </button>
          <button type="button" className="btn btn-next w-100" onClick={toggle}>
            No
          </button>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

const SellPriceModal = forwardRef(
  ({ showModal, toggle, confirmClick, errors, submit }, ref) => (
    <Modal
      isOpen={showModal}
      toggle={toggle}
      className="modal-dialog-centered claim-modal claimed-modal"
    >
      <ModalBody style={{ padding: "0 1rem" }}>
        <form onSubmit={submit}>
          <p className="pb-1 thank-you-text text-center">Sell Card</p>
          <div className="input-group">
            <label>Set Selling Price(USD)</label>
            <TextField
              type="number"
              ref={ref}
              errors={errors?.price}
              placeholder="Selling Price"
              name="price"
            />
            <div style={USDIcon} className="show-password-toggle">
              <span className="input-group-text">
                <IoLogoUsd />
              </span>
            </div>
          </div>

          <div className="d-flex mt-4">
            <button
              type="button"
              className="btn btn-next w-100 mr-2"
              onClick={confirmClick}
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-next w-100"
              onClick={toggle}
            >
              Cancel
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
);

const RenderCard = (
  data,
  type,
  allowedSell,
  allowedMarketplace,
  userId,
  cancelSellClick,
  sellClicked,
  copyToClipboard,
  hoverText,
  backClick
) => {
  const isOwner = () => {
    if (data?.owner?.id === userId) return true;
    return false;
  };
  switch (type) {
    case CardAssetTypes.image:
      return (
        <ImageCardDetails
          data={data}
          allowedSell={allowedSell}
          allowedMarketplace={allowedMarketplace}
          userId={userId}
          cancelSellClick={cancelSellClick}
          sellClicked={sellClicked}
          copyToClipboard={copyToClipboard}
          hoverText={hoverText}
          backClick={backClick}
          isOwner={isOwner}
        />
      );

    case CardAssetTypes.text:
      return (
        <TextCardDetails
          data={data}
          allowedSell={allowedSell}
          allowedMarketplace={allowedMarketplace}
          userId={userId}
          cancelSellClick={cancelSellClick}
          sellClicked={sellClicked}
          copyToClipboard={copyToClipboard}
          hoverText={hoverText}
          backClick={backClick}
          isOwner={isOwner}
        />
      );

    case CardAssetTypes.stream:
      return (
        <StreamCardDetails
          data={data}
          allowedSell={allowedSell}
          allowedMarketplace={allowedMarketplace}
          userId={userId}
          cancelSellClick={cancelSellClick}
          sellClicked={sellClicked}
          copyToClipboard={copyToClipboard}
          hoverText={hoverText}
          backClick={backClick}
          isOwner={isOwner}
        />
      );

    case CardAssetTypes.video:
      return (
        <VideoCardDetails
          data={data}
          allowedSell={allowedSell}
          allowedMarketplace={allowedMarketplace}
          userId={userId}
          cancelSellClick={cancelSellClick}
          sellClicked={sellClicked}
          copyToClipboard={copyToClipboard}
          hoverText={hoverText}
          backClick={backClick}
          isOwner={isOwner}
        />
      );
    case CardAssetTypes.illustration:
      return (
        <IllustrationCardDetails
          data={data}
          allowedSell={allowedSell}
          allowedMarketplace={allowedMarketplace}
          userId={userId}
          cancelSellClick={cancelSellClick}
          sellClicked={sellClicked}
          copyToClipboard={copyToClipboard}
          hoverText={hoverText}
          backClick={backClick}
          isOwner={isOwner}
        />
      );

    default:
      return null;
  }
};

export const CardDetails = () => {
  let { id } = useParams();
  const { data, loading, setData } = CardDetailsHook(id);
  const { data: historyData, loading: historyLoading } = CardHistoryHook(id);
  const { data: canSell } = CanSellHook();

  const [showModal, setShowModal] = useState(false);
  const [showSellingModal, setShowSellingModal] = useState(false);
  const [showBankModal, setShowBankModal] = useState(false);
  const [bankSetupError, setBankSetupError] = useState("");
  const [bankSetupLoading, setBankSetupLoading] = useState(false);
  const { user } = AuthHook();
  const [hoverText, setHoverText] = useState("Click to copy");
  const history = useHistory();

  const allowedSell = useMemo(
    () => (user?.authorities || []).includes(Roles.sellCard),
    [user]
  );
  const allowedMarketplace = useMemo(
    () => (user?.authorities || []).includes(Roles.marketplace),
    [user]
  );

  const {
    register: frmSelling,
    errors,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(sellingSchema),
  });

  const sellCard = async (formData) => {
    try {
      const price =
        Math.round((formData.price * 100 + Number.EPSILON) * 100) / 100;
      await CardsService.sell(id, price);
      setData({
        ...data,
        forSale: true,
        sellPrice: price,
      });
      setShowSellingModal(false);
    } catch (error) {
    } finally {
    }
  };

  const sellClicked = () => {
    if (canSell) {
      reset({
        price: data.buyPrice / 100,
      });
      setShowSellingModal(true);
    } else {
      setShowBankModal(true);
    }
  };

  const cancelCard = async () => {
    try {
      await CardsService.cancel(id);
      setShowModal(false);
      setData({
        ...data,
        forSale: false,
      });
    } catch (error) {}
  };

  const copyToClipboard = () => {
    const textArea = document.createElement("textarea");
    textArea.value = window.location.href;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
    setHoverText("Copied to clipboard");
    setTimeout(() => {
      setHoverText("Click to copy");
    }, 1500);
  };

  const cancelSellClick = () => {
    setShowModal(true);
  };

  const backClick = () => {
    history.goBack();
  };

  const initBankSetup = async () => {
    try {
      setShowBankModal(false);
      setBankSetupLoading(true);
      const result = await UserService.setupBank();
      window.location.href = result;
    } catch (error) {
      setBankSetupError(error?.error?.title || ErrorMessages.default);
    } finally {
      setBankSetupLoading(false);
    }
  };

  return (
    <div className="payment">
      {/* <PageHeader title='MY COLLECTION' /> */}
      {loading ? (
        <LoadingBar />
      ) : (
        RenderCard(
          data,
          data?.assetType || "",
          allowedSell,
          allowedMarketplace,
          user?.id,
          cancelSellClick,
          sellClicked,
          copyToClipboard,
          hoverText,
          backClick
        )
      )}
      <History data={historyData} loading={historyLoading} />
      <ConfirmCancelSell
        showModal={showModal}
        toggle={() => setShowModal(false)}
        confirmClick={cancelCard}
      />
      <SellPriceModal
        showModal={showSellingModal}
        toggle={() => setShowSellingModal(false)}
        confirmClick={handleSubmit(sellCard)}
        errors={errors}
        ref={frmSelling}
      />
      <BankSetupModal
        showModal={showBankModal}
        toggle={() => setShowBankModal(false)}
        confirmClick={initBankSetup}
      />
      <MessagePopup
        showModal={!!bankSetupError}
        toggle={() => setBankSetupError("")}
        message={bankSetupError}
        title="Bank Setup"
      />
      {bankSetupLoading && (
        <OverlayLoadingBar
          dark={true}
          title={"Initializing Bank Setup, please wait"}
        />
      )}
    </div>
  );
};
