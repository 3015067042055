
import React from 'react'
import {
    Modal, ModalBody,
} from 'reactstrap'

export const TermsAcceptPopup = ({ showModal, toggle, termAccepted }) => (
    <Modal isOpen={showModal} toggle={toggle} className="modal-dialog-centered  py-4"  >
        <ModalBody className="px-4">
            <div>
                <h2 className='signin-textstyle black'>Terms</h2>

                <p>
                    By proceeding you agree to the  <a className='linkSuccessGreen' href="https://vuele.io/terms-of-service/" target="_blank" rel="noopener noreferrer" >Terms of Service</a>
                </p> and the
                <a className="mr-1 linkSuccessGreen" href="https://vuele.io/privacy-policy/" target="_blank" rel="noopener noreferrer" > Privacy Policy</a>


                <button
                    type="submit"
                    className='btn btn-custom-form btn-block btn-lg'
                    onClick={termAccepted}
                >
                    Accept
                </button>
            </div>
        </ModalBody>
    </Modal>
)