import { useEffect, useState } from "react";
import { MarketplaceService } from "utility/services";
import { ErrorMessages } from "utility/constants/common";

export const MarketplaceHook = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
  });
  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await MarketplaceService.get(filter);
      setData(data.concat((result || [])));
      setHasMore((result || []).length === filter.size);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filter) {
        fetchData();
    }
},[filter]);

  const pageChanged = () => {
    if (!hasMore) return;
    const temp = {
      ...filter,
      page: filter.page + 1,
    };
    setFilter({ ...temp });
  };
  return { data, loading, error, pageChanged, hasMore };
};
export const MarketplaceCountHook = () => {
  const [count, setCount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await MarketplaceService.getCount();
      setCount(result);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { count, loading, error };
};
