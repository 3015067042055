import React, { useMemo, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Images } from "img";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  ErrorMessages,
  PaymentType,
  TransactionStatus,
} from "utility/constants/common";
import { IoIosArrowBack } from "react-icons/io";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "elements/Inputs";
import { DetailsHook } from "hooks/payment";
import { CardsService, PacksService, UserService } from "utility/services";
import { LoadingBar, OverlayLoadingBar } from "components/LoadingBar";
import CommonUtility from "utility/common";
import { BrowserUtility } from "utility/browser-utility";
import ReactPixel from "@bettercart/react-facebook-pixel";
import { HistoryByIdHook } from "hooks/user";
import { Modal, ModalBody } from "reactstrap";
import { MessagePopup } from "components/MessagePopup";
import CoinbaseCommerceButton from "react-coinbase-commerce";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { AuthHook } from "context/auth";

const ConfirmPaymentModal = ({ showModal, toggle, confirmClick, price }) => (
  <Modal
    isOpen={showModal}
    toggle={toggle}
    className="modal-dialog-centered claim-modal claimed-modal"
  >
    <ModalBody style={{ padding: "0 1rem" }}>
      <div>
        <p className="text-center">
          <IoIosInformationCircleOutline color="#DE9E00" size="100px" />
        </p>

        <p className="my-4 thank-you-text text-center">Payment Confirmation</p>

        <p className="my-4 text-center description-text">
          Are you sure you want to buy this card?
        </p>
        <p className="my-4 text-center description-text payment-text">
          {price}
        </p>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-next w-100 mr-2"
            onClick={confirmClick}
          >
            Yes
          </button>
          <button type="button" className="btn btn-next w-100" onClick={toggle}>
            No
          </button>
        </div>
      </div>
    </ModalBody>
  </Modal>
);
const Details = ({ data, loading, backClick, type, price }) => (
  <div className="payment-content">
    <div className="back-button" onClick={backClick}>
      <IoIosArrowBack color="#84c142" className="back-button-icon" />
      {type === PaymentType.card ? "Back to NFT Sale" : "Back"}
    </div>
    {loading ? (
      <LoadingBar />
    ) : (
      <>
        <span className="pre-headline">
          {type === PaymentType.card ? "Purchase NFT" : "Purchase Pack Sale"}
        </span>
        <h2 className="headline">{type === "card" ? data.name : data.name}</h2>
        <h3 className="sub-headline">
          {type === "card" ? data.series : data.series}
        </h3>
        {price && (
          <h3 className="sub-headline">
            Price: {CommonUtility.currencyFormat(price / 100)}
          </h3>
        )}
        <div>{data?.description}</div>
      </>
    )}
  </div>
);

const useOptions = () => {
  const fontSize = "1.125rem";
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#84c142",
          letterSpacing: "0.025em",
          "::placeholder": {
            color: "#d9efb6",
          },
        },
        invalid: {
          color: "#dc3545",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const paymentSchema = yup.object().shape({
  name: yup.string().required("card holder name is required"),
  saveCard: yup.boolean(),
});
const registerSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(4, "Password should be must be between 4 and 100")
    .max(100, "Password should be must be between 4 and 100"),
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid Email Address"),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref("email"), null], "email must match")
    .required("Confirm Email is required"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
export const ExpressCheckout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [payWith, setPayWith] = useState("");
  const [formData, setFormData] = useState(null);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const {
    register: frmPayment,
    errors,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(paymentSchema),
  });
  const {
    register,
    errors: registerError,
    handleSubmit: handleRegisterUser,
  } = useForm({
    resolver: yupResolver(registerSchema),
  });
  let { search } = useLocation();
  ReactPixel.init(
    process.env.REACT_APP_META_PIXEL_ID,
    {},
    {
      autoConfig: true,
      debug: false,
    }
  );
  useEffect(() => {
    const id = new URLSearchParams(search).get("id");
    if (id) {
      setId(id);
    }
    const type = new URLSearchParams(search).get("type");
    if (type) {
      setType(type);
    }
  }, [search]);

  const { data: details, loading: detailsLoading } = DetailsHook(id, type);

  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [transactionId, setTransactionId] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [chargeCode, setChargeCode] = useState("");
  const [registerUserLoading, setRegisterLoading] = useState(false);
  const {
    data: transaction,
    error: transactionError,
    refreshData,
  } = HistoryByIdHook(transactionId);
  const [savedCardProcessing, setSavedCardProcessing] = useState(false);
  const history = useHistory();
  const { user, login: loginContext, isAuthenticated } = AuthHook();

  useEffect(() => {
    if (transactionError) {
      setError(transactionError?.error?.title || ErrorMessages.default);
      setTransactionId(null);
    }
  }, [transactionError]);
  const trackPurchase = () => {
    if (details?.price || details?.sellPrice) {
      ReactPixel.track(
        "Purchase",
        {
          currency: "USD",
          value: details?.price
            ? details?.price / 100
            : details?.sellPrice / 100,
          email: user?.email,
          phone: user?.mobileNumber,
          productId: transaction.pack
            ? transaction?.pack?.id
            : transaction?.card?.id,
          productUrl: window.location.href,
          productDescription: details?.description,
          userFirstName: user?.firstName,
          userLastName: user?.lastName,
          productPrice: details?.price
            ? details?.price / 100
            : details?.sellPrice / 100,
        },
        {
          eventID: transaction.pack
            ? transaction?.pack?.id
            : transaction?.card?.id,
          fbc: BrowserUtility.getCookie("_fbc")
            ? BrowserUtility.getCookie("_fbc")
            : "",
          fbp: BrowserUtility.getCookie("_fbp")
            ? BrowserUtility.getCookie("_fbp")
            : "",
        }
      );
    }
  };
  useEffect(() => {
    const fetch = async () => {
      switch (transaction?.transactionStatus) {
        case TransactionStatus.crypto_waiting:
          setChargeCode(transaction?.cryptoChargeCode);
          break;
        case TransactionStatus.success:
          setTransactionId("");
          // await claimPack(transaction?.pack?.id);
          trackPurchase()
         
          onTransactionComplete();
          break;

        case TransactionStatus.fail:
          setTransactionId("");
          setError(transaction?.notes);
          break;

        default:
          break;
      }
    };
    fetch();
  }, [transaction]);
  const afterLogin = (result) => {
    UserService.storeToken(result.id_token);

    setTimeout(async () => {
      const user = await UserService.get();
      UserService.storeUser(user);
      loginContext(user);
    });
  };
  const registerUser = async (data) => {
    try {
      setModal(false);
      setRegisterLoading(true);
      const req = {
        ...data,
        login: "any",
      };
      const loginReq = {
        password: data?.password,
        username: data?.email,
      };
      await UserService.register(req);
      const result = await UserService.login(loginReq);
      afterLogin(result);
    } catch (err) {
      setError(err?.error?.title || ErrorMessages.default);
    } finally {
      await UserService.forgotPassword({ email: data?.email });
      setRegisterLoading(false);
    }
  };
  useEffect(() => {
    if (!transactionId) return;
    const interval = setInterval(() => {
      refreshData();
    }, 5000);
    return () => clearInterval(interval);
  }, [transactionId]);

  useEffect(() => {
    if (chargeCode) {
      setTimeout(() => {
        const button = document.getElementsByClassName("coinbase-button");
        if (button.length > 0) {
          button[0].click();
        }
      }, 500);
    }
  }, [chargeCode]);
  const confirmPayment = async () => {
    if (!UserService.isAuthenticated()) {
      await handleRegisterUser(registerUser)();
    }

    if (
      Object.keys(registerError).length === 0 &&
      UserService.isAuthenticated()
    ) {
      switch (payWith) {
        case "PAY_WITH_SAVED_CARD":
          buyUsingSavedCard();
          break;
        case "PAY_WITH_CRYPTO":
          buyUsingCrypto();
          break;
        case "PAY_WITH_CARD":
          buy(formData);
          break;
        default:
          break;
      }
    } else {
      setModal(false);
    }
  };
  const handlePayWithCard = (type) => async (formData) => {
    setModal(true);
    setPayWith(type);
    setFormData(formData);
  };
  const buy = async (formData) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setProcessing(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: formData.name,
      },
    });
    if (error) {
      setProcessing(false);
      setError(error.message);
    } else {
      try {
        let result;
        // await PaymentService.add(paymentMethod.id);
        if (type === "pack") {
          result = await PacksService.buy(
            id,
            {
              paymentMethodVM: {
                paymentMethodId: paymentMethod.id,
                saveCard: formData.saveCard,
              },
              sendFacebookEvent: true,
              userFirstName: user?.firstName,
              userLastName: user?.lastName,
              productUrl: window.location.href,
              fbc: BrowserUtility.getCookie("_fbc")
                ? BrowserUtility.getCookie("_fbc")
                : "",
              fbp: BrowserUtility.getCookie("_fbp")
                ? BrowserUtility.getCookie("_fbp")
                : "",
            },
            { autoOpenPack: true }
          );
        } else if (type === "card") {
          result = await CardsService.buy(id, {
            paymentMethodId: paymentMethod.id,
            saveCard: formData.saveCard,
          });
        }
        setTransactionId(result?.id);
        setModal(false);
      } catch (error) {
        setError(error?.error?.title || ErrorMessages.default);
        setModal(false);
      } finally {
        setProcessing(false);
      }
    }
  };
  const buyUsingSavedCard = async () => {
    try {
      setSavedCardProcessing(true);
      let result;
      if (type === "pack") {
        result = await PacksService.buy(
          id,
          {
            sendFacebookEvent: true,
            userFirstName: user?.firstName,
            userLastName: user?.lastName,
            productUrl: window.location.href,
            fbc: BrowserUtility.getCookie("_fbc")
              ? BrowserUtility.getCookie("_fbc")
              : "",
            fbp: BrowserUtility.getCookie("_fbp")
              ? BrowserUtility.getCookie("_fbp")
              : "",
          },
          { autoOpenPack: true }
        );
      } else if (type === "card") {
        result = await CardsService.buy(id, null);
      }
      setTransactionId(result?.id);
      setModal(false);
    } catch (error) {
      setError(ErrorMessages.default);
      setModal(false);
    } finally {
      setSavedCardProcessing(false);
    }
  };

  const claimPack = async (id) => {
    try {
      await PacksService.open(id);
    } catch (error) {
      console.log(error);
    }
  };

  const backClick = () => {
    history.goBack();
  };
  const buyUsingCrypto = async () => {
    try {
      setProcessing(true);
      let result;
      if (type === "pack") {
        result = await PacksService.buy(
          id,
          {
            paymentMethodVM: { isCrypto: true },
            sendFacebookEvent: true,
            userFirstName: user?.firstName,
            userLastName: user?.lastName,
            productUrl: window.location.href,
            fbc: BrowserUtility.getCookie("_fbc")
              ? BrowserUtility.getCookie("_fbc")
              : "",
            fbp: BrowserUtility.getCookie("_fbp")
              ? BrowserUtility.getCookie("_fbp")
              : "",
          },
          { autoOpenPack: true }
        );
      } else if (type === "card") {
        result = await CardsService.buy(id, { isCrypto: true });
      }
      setTransactionId(result?.id);
      setModal(false);
    } catch (error) {
      setError(error?.error?.title || ErrorMessages.default);
      setModal(false);
    } finally {
      setProcessing(false);
    }
  };

  const coinBaseError = (error) => {
    setTransactionId("");
    setError(error);
  };

  const onTransactionComplete = async () => {
    setProcessing(false);
    setTransactionId(false);
    history.push(`/app/payment-confirmation?id=${id}&type=${type}`);
  };
  const onCloseCoinBase = () => {
    history.push(`/app/payment?id=${id}&type=${type}`);
  };
  return (
    <div className="payment">
      <div className="header-background">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 offset-lg-1 col-xl-4 offset-xl-1 order-1">
              <Details
                backClick={backClick}
                data={details}
                loading={detailsLoading}
                price={type === "pack" ? details?.price : 0}
                type={type}
              />
            </div>
            <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-0 order-2">
              <div className="popup-box">
                <label className="popup-title">SELECT METHOD</label>
                <button
                  type="button"
                  disabled={processing || registerUserLoading}
                  onClick={() => {
                    setPayWith("PAY_WITH_CRYPTO");
                    setModal(true);
                  }}
                  className="btn btn-custom-form w-100 mt-3"
                >
                  {processing || registerUserLoading
                    ? "Processing"
                    : "Pay with Crypto"}
                </button>
                {!showForm && (
                  <button
                    type="button"
                    onClick={() => setShowForm(true)}
                    className="btn btn-custom-form w-100 mt-3"
                  >
                    Add New Card
                  </button>
                )}
                <form
                  className="mt-4"
                  noValidate
                  onSubmit={handleSubmit(handlePayWithCard("PAY_WITH_CARD"))}
                >
                  {showForm && (
                    <>
                      <div className="row no-gutters mb-4 mt-2">
                        <div className="card-provider-img">
                          <img src={Images.payment.visa} alt="Visa Card" />
                        </div>

                        <div className="card-provider-img">
                          <img src={Images.payment.master} alt="Master Card" />
                        </div>
                        <div className="card-provider-img">
                          <img
                            src={Images.payment.discover}
                            alt="Discover Card"
                          />
                        </div>
                      </div>

                      <label className="popup-title pb-2">
                        PAYMENT DETAILS
                      </label>

                      <div className="form-group">
                        <CardNumberElement
                          className="textfield-1"
                          options={options}
                        />
                      </div>
                      <div className="form-group">
                        <CardExpiryElement
                          className="textfield-1"
                          options={options}
                        />
                      </div>
                      <div className="form-group">
                        <CardCvcElement
                          className="textfield-1"
                          options={options}
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          ref={frmPayment}
                          errors={errors?.name}
                          placeholder="Card Holder Name"
                          name="name"
                        />
                      </div>
                    </>
                  )}
                  {!UserService.isAuthenticated() && (
                    <div>
                      <label className="popup-title mt-3">CREATE ACCOUNT</label>
                      <div className="form-group">
                        <TextField
                          ref={register}
                          errors={registerError?.email}
                          placeholder="Email Address"
                          name="email"
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          ref={register}
                          errors={registerError?.confirmEmail}
                          placeholder="Confirm Email"
                          name="confirmEmail"
                        />
                      </div>

                      <div className="input-group mb-3">
                        <TextField
                          ref={register}
                          type={showPassword ? "text" : "password"}
                          errors={registerError?.password}
                          placeholder="Enter Password"
                          name="password"
                        />

                        <div className="show-password-toggle">
                          <span
                            className="input-group-text"
                            onClick={(e) => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? (
                              <BsEye color="#d9efb6" />
                            ) : (
                              <BsEyeSlash color="#d9efb6" />
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="input-group mb-3">
                        <TextField
                          ref={register}
                          type={showConfirmPassword ? "text" : "password"}
                          errors={registerError?.confirmPassword}
                          placeholder="Confirm Password"
                          name="confirmPassword"
                        />

                        <div className="show-password-toggle">
                          <span
                            className="input-group-text"
                            onClick={(e) => {
                              setShowConfirmPassword(!showConfirmPassword);
                            }}
                          >
                            {showConfirmPassword ? (
                              <BsEye color="#d9efb6" />
                            ) : (
                              <BsEyeSlash color="#d9efb6" />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {showForm && (
                    <>
                      <div className="agreement-text-style">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name="saveCard"
                            ref={frmPayment}
                          />
                          <span className="default"></span>
                        </label>
                        Save card for future payment
                      </div>
                      {/* <div className="form-group text-danger">
										{error}
									</div> */}
                      <div className="form-group mt-4">
                        <button
                          type="submit"
                          disabled={
                            processing ||
                            savedCardProcessing ||
                            registerUserLoading
                          }
                          className="btn btn-next w-100"
                        >
                          {processing || registerUserLoading
                            ? "Processing"
                            : "Next"}
                        </button>
                      </div>
                      <div>
                        <small>
                          VUELE<sup>TM</sup> uses CurrencyWorks Inc. (CSE: CWRK
                          and OTCQB: CWRK) and third party software providers to
                          process payments on behalf of VUELE<sup>TM</sup>. As a
                          result receipts for purchases will be sent from
                          CurrencyWorks Inc., and CWRK may show up on your bank
                          or credit card statement.
                        </small>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MessagePopup
        showModal={!!error}
        toggle={() => setError("")}
        message={error}
        title="Payment"
      />
      <ConfirmPaymentModal
        showModal={modal}
        toggle={toggleModal}
        confirmClick={confirmPayment}
        price={CommonUtility.currencyFormat(
          details.price ? details.price / 100 : details.sellPrice / 100
        )}
      />
      <CoinbaseCommerceButton
        styled={false}
        chargeId={chargeCode}
        onChargeSuccess={() => onTransactionComplete()}
        onChargeFailure={(e) => coinBaseError(e.code)}
        onModalClosed={() => onCloseCoinBase()}
        className="coinbase-button"
      >
        Complete Crypto Transaction
      </CoinbaseCommerceButton>
      {transactionId && (
        <OverlayLoadingBar
          dark={true}
          title={"We are preparing your pack, please wait..."}
        />
      )}
    </div>
  );
};
