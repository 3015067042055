import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "react-coinbase-commerce/dist/coinbase-commerce-button.css";
import "react-image-lightbox/style.css";
import { BrandingProvider } from "context/branding";

ReactDOM.render(
  <BrandingProvider>
    <App />
  </BrandingProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
