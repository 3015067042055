import React from 'react'

export const ErrorMessageBox = ({ dark,title,children }) => (
    <div className="row justify-content-center align-items-center w-100">
        <div className="col-lg-12">
            <div className={`empty-state-container ${dark ? "dark" : "light"}`}>
                <h2>{title}</h2>
                {children}
            </div>
        </div>
    </div>
)