import { LoadingBar } from 'components/LoadingBar';
import { HistoryHook } from 'hooks/user';
import { Images } from 'img';
import React from 'react'
import CommonUtility from 'utility/common';
import { TransactionStatus } from 'utility/constants/common';
import DateUtility from 'utility/date';
import CoinbaseCommerceButton from "react-coinbase-commerce";

export const Transactions = () => {

    const { data,loading,hasMore,pageChanged ,refreshData} = HistoryHook();
    const transactionSuccess = (e) => {
        refreshData();
    }
    return (
        <div className='payment'>
            <div className='header-background'>
                <div className='container'>

                    <div className='row justify-content-center'>
                        <div className="col-12">
                            <table className="table text-white">
                                <thead>
                                    <tr>
                                        <th className="w-15"></th>
                                        <th className="w-20">Item</th>
                                        <th className="w-10">Price</th>
                                        <th className="w-15">From</th>
                                        <th className="w-15">To</th>
                                        <th className="w-15">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(item => (
                                        <tr key={item.id}>
                                            <td className="w-15">
                                                <div className="transaction-image">
                                                    {item.transactionFor === "PACK" ? <img
                                                        src={item.pack?.packTemplate?.picture?.url || Images.defaultPack}
                                                        alt={item.pack?.packTemplate?.name || "Pack Image"}
                                                    /> :
                                                        <img
                                                            src={item.card?.icon?.url || Images.defaultPack}
                                                            alt={item.card?.name || "Card Image"}
                                                        />}
                                                </div>
                                            </td>
                                            <td className="w-20">
                                                {item.transactionStatus === TransactionStatus.fail ? <>
                                                    <p className="mb-1 text-danger">{item.notes}</p>
                                                    <p className="badge badge-danger">Failed</p>
                                                </> : <>
                                                    {item.transactionFor === "PACK" ? <span>
                                                        {item.pack?.packTemplate?.name}
                                                    </span> : <div>
                                                        <div>
                                                            {item.card?.name}
                                                        </div>
                                                        <div>
                                                            #{item.card?.groupSequence} of {item.card?.maxExisting}
                                                        </div>
                                                    </div>
                                                    }
                                                    {item.transactionStatus === TransactionStatus.pending && <span className="badge badge-info">Pending</span>}
                                                </>}
                                                {item.transactionStatus === TransactionStatus.crypto_waiting &&
                                                    <CoinbaseCommerceButton
                                                        styled={true}
                                                        chargeId={item.cryptoChargeCode}
                                                        onChargeSuccess={transactionSuccess}
                                                        onPaymentDetected={(e) => console.log(e)}
                                                        onChargeFailure={(e) => console.log(e)}
                                                        className="btn btn-next btn-sm"
                                                    >
                                                        Complete Crypto Transaction
                                                    </CoinbaseCommerceButton>
                                                }
                                            </td>
                                            <td className="w-10">{CommonUtility.currencyFormat(item.buyPrice / 100)}</td>
                                            <td className="w-15">{`${item.buyer?.userName || ""}`}</td>
                                            <td className="w-15">{item.sellPrice ? `${item.seller?.userName}` : ''}</td>
                                            <td className="w-15">{DateUtility.formatDate(item.createdDate)}</td>
                                        </tr>
                                    ))}

                                    {!loading && data.length === 0 && (
                                        <tr colSpan="5">
                                            <td>No Data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {loading && <LoadingBar />}
                        {hasMore && <div className="col-12 text-center my-4">
                            <button
                                className='btn btn-next px-4 btn-green-custom'
                                onClick={() => pageChanged()}
                                disabled={loading}
                            >
                                {loading ? 'Loading...' : 'Load more data'}
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
