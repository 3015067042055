import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { Payment } from "./Payment";
import { PaymentConfirmation } from "./PaymentConfirmation";
import { EditProfile } from "./EditProfile";
import { Play } from "./Play";
import { MyCards } from "./MyCards";
import { CardDetails } from "./CardDetails";
import { Transactions } from "./Transactions";
import { Marketplace } from "./Marketplace";
import { ChangePassword } from "./ChangePassword";

export const AppScreen = () => {
  let { path } = useRouteMatch();

  return (
    <React.Fragment>
      {/* <Route
                    path={`${path}/home`}
                    component={Home}
                /> */}
      <Route path={`${path}/my-nfts`} component={MyCards} />
      <Route path={`${path}/nft-details/:id`} component={CardDetails} />

      <Route path={`${path}/play/:id`} component={Play} />
      <Route path={`${path}/payment`} component={Payment} />
      <Route
        path={`${path}/payment-confirmation`}
        component={PaymentConfirmation}
      />
      <Route path={`${path}/edit-profile`} component={EditProfile} />
      <Route path={`${path}/change-password`} component={ChangePassword} />
      <Route path={`${path}/transactions`} component={Transactions} />
      <Route path={`${path}/marketplace`}>
        <Marketplace />
      </Route>
      <Route path={path} exact>
        <Redirect to={`${path}/`} />
      </Route>
    </React.Fragment>
  );
};
