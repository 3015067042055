import { createContext,useEffect,useState,useContext } from "react";
import { Redirect } from "react-router";
import { Route } from "react-router-dom";
import { UserService } from "utility/services";
import React from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated,setIsAuthenticated] = useState(null);
    const [user,setUser] = useState(null);
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        const loadToken = () => {
            setIsAuthenticated(UserService.isAuthenticated());
            setUser(UserService.getUser());
            setLoading(false)
        }
        loadToken()
    },[])

    const login = (data) => {
        setIsAuthenticated(true);
        setUser(data);
    }

    const logout = () => {
        setIsAuthenticated(false);
        setUser(null);
    }

    return (
        <AuthContext.Provider value={{ user,loading,isAuthenticated,login,logout }}>
            {children}
        </AuthContext.Provider>
    )
}

export const AuthHook = () => useContext(AuthContext)

export const ProtectedRoute = ({ children,...rest }) => {
    const { isAuthenticated,loading } = AuthHook();

    if (loading) {
        return null;
    }
    return (
        <Route
            {...rest}
            render={() =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                        }}
                    />
                )
            }
        />
    )

};