import React, { useState } from 'react'
import Document from 'layout/Document'
import GoogleButton from 'components/GoogleButton'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { Link, useHistory } from 'react-router-dom'
import { Images } from 'img'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MobileFormField, TextField } from 'elements/Inputs'
import { UserService } from 'utility/services'
import { ErrorMessages } from 'utility/constants/common'
import { AuthHook } from 'context/auth'
import { TermsAcceptPopup } from 'components/TermsAcceptPopup'
import ReCAPTCHA from "react-google-recaptcha";
import { BrandingContextHook } from 'context/branding'
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KET;

const registerSchema = yup.object().shape({
	password: yup.string().required("Password is required")
		.min(4, "Password should be must be between 4 and 100")
		.max(100, "Password should be must be between 4 and 100"),
	email: yup.string().required("Email is required").email("Invalid Email Address"),
	firstName: yup.string().required("First Name is required"),
	lastName: yup.string().required("Last Name is required"),
	mobileNumber: yup.string().trim().required("Mobile number is required").test("invalid", "Invalid Mobile Number",
		(value) => {
			const valid = isValidPhoneNumber(value) && isPossiblePhoneNumber(value)
			return valid;
		})
});

const Register = () => {

	const {
		register,
		errors,
		handleSubmit,
		control,
	} = useForm({
		resolver: yupResolver(registerSchema)
	});
	const { login: loginContext } = AuthHook();
	const { data: branding } = BrandingContextHook();

	const history = useHistory();

	const [success, setSuccess] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [termChecked, setTermChecked] = useState(false);
	const [showAcceptTerm, setShowAcceptTerm] = useState(false);
	const [googleToken, setGoogleToken] = useState('');
	const [captchaKey, setCaptchaKey] = useState('');


	const afterLogin = (result) => {
		UserService.storeToken(result.id_token);
		setTimeout(async () => {
			const user = await UserService.get();
			UserService.storeUser(user);
			loginContext(user);
			history.push('/app')
		});
	}

	const googleLogin = async (data) => {
		try {
			setLoading(true);
			const result = await UserService.login({
				googleIdToken: googleToken
			});
			afterLogin(result);
		} catch (err) {
			console.log(err);
			setError(ErrorMessages.default);
		} finally {
			setLoading(false);
		}
	}

	const googleLoginInit = (data) => {
		setGoogleToken(data.tokenId);
		setShowAcceptTerm(true);
	}

	const onSubmit = async (data) => {
		try {
			setLoading(true);
			const req = {
				...data,
				login: "any"
			};
			if (branding?.reCaptchaEnabled) {
				req.reCaptchaToken = captchaKey
			}
			await UserService.register(req);
			setSuccess(true);
		} catch (err) {
			setError(err?.error?.title || ErrorMessages.default);
		} finally {
			setLoading(false);
		}
	}

	return (
		<Document title='Register' className='login-page'>
			<div>

				<div
					className="BackgoundContainer"
					style={{
						backgroundImage: `url(${Images.home})`
					}} />

				<div className="login-container">
					<div className="container">

						<div className='row justify-content-center'>

							<div className='col-lg-6'>
								<div className='login-logo-container'>
									<img src={Images.logo.big} width='300' alt='Logo' />
								</div>
							</div>

							<div className='col-lg-6'>

								<div className="login-box" style={{ top: 30 }}>

									<h2 className='signin-textstyle'>Sign Up</h2>
									{success ? <div className="text-center">
										<p className='register-text-style mt-3'>
											Email has been sent to your email address, please check your inbox to activate your account.
										</p>
										<Link to='/login' className='linkDefalut'>
											Back to login
										</Link>
									</div> : <>

										<GoogleButton
											text='Register with Google'
											type='register'
											onSuccess={googleLoginInit}
										/>

										<div className='or-text'>or</div>

										<form name='formLogin' noValidate onSubmit={handleSubmit(onSubmit)}>

											<div className='mb-3'>
												<TextField
													ref={register}
													errors={errors?.firstName}
													placeholder="First Name"
													name="firstName"
												/>
											</div>

											<div className='mb-3'>
												<TextField
													ref={register}
													errors={errors?.lastName}
													placeholder="Last Name"
													name="lastName"
												/>
											</div>

											<div className='mb-3'>
												<TextField
													ref={register}
													errors={errors?.email}
													placeholder="Email Address"
													name="email"
												/>
											</div>

											<div className='input-group mb-3'>
												<TextField
													type={showPassword ? 'text' : 'password'}
													ref={register}
													errors={errors?.password}
													placeholder="Password"
													name="password"
												/>

												<div className='show-password-toggle'>
													<span
														className='input-group-text'
														onClick={e => {
															setShowPassword(!showPassword);
														}}
													>
														{showPassword ? <BsEye color="#d9efb6" /> : <BsEyeSlash color="#d9efb6" />}
													</span>
												</div>

											</div>
											<div className='mb-3'>
												<MobileFormField
													control={control}
													defaultCountry="US"
													errors={errors?.mobileNumber}
													placeholder="Mobile Number"
													name="mobileNumber"
												/>
											</div>

											<div className='agreement-text-style'>

												<label className='checkbox'>
													<input type='checkbox' checked={termChecked} onChange={(e) => setTermChecked(e.target.checked)} />
													<span className='default'></span>
												</label>

												By checking here and continuing, I agree to the{' '}
												<a rel="noopener noreferrer" href='https://motoclub.io/terms-of-service/' target="_blank">Terms of Use</a>{' '}and{' '}<a rel="noopener noreferrer" href='https://vuele.io/privacy-policy/' target="_blank">Privacy Policy.</a>


											</div>
											<div className="my-2">
												{branding?.reCaptchaEnabled && <ReCAPTCHA
													sitekey={siteKey}
													onChange={(value) => setCaptchaKey(value)}
												/>}
											</div>
											{error && <div className="text-danger my-2">
												{error}
											</div>}

											<button
												type="submit"
												disabled={!!loading || !termChecked}
												className='btn btn-custom-form btn-block btn-lg'
											>
												{loading
													? 'Loading...'
													: 'Sign Up'}
											</button>

										</form>

										<h4 className='register-text-style mt-3'>
											Already have an account?{' '}
											<Link to='/login' className='linkDefalut'>
												Sign in
											</Link>
										</h4>
									</>
									}
								</div>
							</div>
						</div>
					</div>
					<TermsAcceptPopup
						showModal={showAcceptTerm}
						toggle={() => setShowAcceptTerm(false)}
						termAccepted={() => googleLogin()}
					/>
				</div>
			</div>
		</Document >
	)
}

export default Register;
