import React from "react";
import styled from "styled-components";
import { Images } from "img";
import {
  Qty,
  Description,
  BackButton,
  MediaAction,
  Title,
  MediaType,
  MediaTypeIcon,
  DetailSection,
  PageContainer,
  Credits,
} from "./common";
import CommonUtility from "utility/common";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { IoIosLink } from "react-icons/io";

const PosterImage = styled.div`
  padding-right: 1rem;

  img {
    max-width: 100%;
  }
`;

export const IllustrationCardDetails = ({
  data,
  allowedSell,
  allowedMarketplace,
  userId,
  cancelSellClick,
  sellClicked,
  copyToClipboard,
  hoverText,
  backClick,
  isOwner,
}) => {
  return (
    <PageContainer>
      <div className="payment">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <DetailSection>
                <div className="row">
                  <div className="col-lg-4 order-2 order-lg-1">
                    <PosterImage>
                      <img
                        src={data?.icon?.url || Images.defaultPack}
                        alt={data?.name}
                      />
                    </PosterImage>
                  </div>
                  <div className="col-lg-6 order-3 order-lg-2">
                    <MediaTypeIcon>
                      <img src={Images.assetType.image} alt="" />
                    </MediaTypeIcon>
                    <MediaType>Digital signed poster</MediaType>
                    <Title>{data?.name}</Title>
                    <Qty>
                      #{data?.groupSequence} of {data?.maxExisting}
                    </Qty>
                    <Description>{data?.text}</Description>

                    {data?.forSale && (
                      <Credits>
                        Selling Price:{" "}
                        {CommonUtility.currencyFormat(data?.sellPrice / 100)}
                      </Credits>
                    )}

                    <MediaAction>
                      <button
                        type="button"
                        className="btn btn-custom-form btn-block btn-lg"
                      >
                        print/export
                      </button>

                      {data?.forSale && (
                        <>
                          {data?.owner?.id === userId ? (
                            <button
                              type="button"
                              className="btn btn-custom-form btn-block btn-lg"
                              onClick={cancelSellClick}
                            >
                              Cancel Sell
                            </button>
                          ) : (
                            <>
                              {allowedMarketplace && (
                                <Link
                                  to={`/app/payment?id=${data.id}&type=card`}
                                >
                                  <button className="btn btn-custom-form btn-block btn-lg">
                                    Buy
                                  </button>
                                </Link>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {allowedSell && !data?.forSale && isOwner() && (
                        <button
                          type="button"
                          onClick={sellClicked}
                          className="btn btn-custom-form btn-block btn-lg"
                        >
                          SELL
                        </button>
                      )}

                      <button
                        className="btn btn-custom-form btn-block btn-lg copy-button"
                        id="nft"
                        onClick={copyToClipboard}
                      >
                        <IoIosLink />
                      </button>
                      <UncontrolledTooltip placement={"auto"} target="nft">
                        {hoverText}
                        <p className="d-lg-none mt-0 mb-0 ml-2"> {hoverText}</p>
                      </UncontrolledTooltip>
                    </MediaAction>
                  </div>
                  <div className="col-lg-2 order-1 order-lg-3 d-flex justify-content-lg-end align-items-lg-start">
                    <BackButton>Back</BackButton>
                  </div>
                </div>
              </DetailSection>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};
