import { DetailsHook } from "hooks/payment";
import React, { useEffect, useState } from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";

export const PaymentConfirmation = () => {
  const [id, setId] = useState("");
  const [type, setType] = useState("");

  let { search } = useLocation();

  useEffect(() => {
    const id = new URLSearchParams(search).get("id");
    if (id) {
      setId(id);
    }
    const type = new URLSearchParams(search).get("type");
    if (type) {
      setType(type);
    }
  }, [search]);

  const { data } = DetailsHook(id, type);


  return (
    <div className="payment">
      <div className="header-background">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="popup-box">
                <p className="text-center">
                  <IoIosCheckmarkCircleOutline
                    color="#84c142"
                    size="100px"
                  ></IoIosCheckmarkCircleOutline>
                </p>

                <p className="my-4 thank-you-text text-center">
                  Thank you for your purchase!
                </p>

                <p className="my-4 mb-5 text-center description-text">
                  Your order of {data?.name} is now available on your
                  collection.
                </p>

                <Link to={"/app/my-nfts"}>
                  <button
                    type="button"
                    className="btn btn-next w-100 mb-5 text-uppercase"
                  >
                    GO TO My Collection
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
