import { LoadingBar } from 'components/LoadingBar';
import { VideoPlayer } from 'components/VideoPlayer';
import { CardVideoOTPHook } from 'hooks/cards';
import React from 'react'
import { useHistory,useParams } from "react-router-dom";
import styled from 'styled-components';

const PlayerContainer = styled.div`
    height : 100vh;
    width : 100%;
`



export const Play = () => {

    const { id } = useParams();
    const history = useHistory();

    const { data,loading } = CardVideoOTPHook(id);

    const backClick = () => {
        history.goBack();
    }

    return (
        <PlayerContainer>
            {loading && <LoadingBar />}
            {data && !loading && <VideoPlayer
                otp={data.otp}
                playbackInfo={data.playbackInfo}
                backClick={backClick}
            />}
        </PlayerContainer>
    )
}