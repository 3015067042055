export const Images = {
    home: require('./home.png'),
    logo: {
        big: require('./logo.png'),
        compact: require('./logo-compact.png'),
    },
    googleIcon: require('./google-icon.svg'),
    wheel: require('./wheel.svg'),
    defaulUserPic: require('./default-user-pic.png'),
    emptyState: require('./empty-state.svg'),
    errorState: require('./error-state.svg'),
    user: require('./user.png'),

    payment: {
        visa: require('./visa.svg'),
        discover: require('./discover.svg'),
        master: require('./master.svg')
    },
    hero: {
        hero1: require('./home-hero-1.png')
    },
    packopen: require('./packopen.webm'),

    film: {
        thumbnail1: require('./video-1.png'),
        thumbnail2: require('./video-2.png'),
        thumbnail3: require('./video-3.png'),
    },

    assetType: {
        text: require('./icon-characterbio.svg'),
        stream: require('./icon-featurefilm.svg'),
        video: require('./icon-characterbio.svg'),
        image: require('./icon-poster.svg'),
        illustration: require('./icon-poster.svg'),
    },

    defaultPack: require('./default-pack.png'),
    playIcon: require('./icon-play.svg'),
    cashout: require('./cashout.svg'),
    footer: {
        metaworks: require('./footer/metaworks-logo@2x.png'),
        enderbyentertainment: require('./footer/enderbyentertainment-white-300x56.png')
    }
}