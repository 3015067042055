import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./sass/main.scss";
import "react-toastify/dist/ReactToastify.css";
// Route Page
import Login from "./screens/Login";
import Register from "./screens/Register";
import RegisterActivation from "./screens/RegisterActivation";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import { AuthProvider, ProtectedRoute } from "./context/auth";
import { AppScreen } from "./screens/app";
import TermsOfUse from "screens/TermsOfUse";
import Footer from "components/Footer";
import { ExpressCheckout } from "screens/ExpressCheckout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BrandingContextHook } from "context/branding";
import Navbar from "layout/Navbar";
import { Releases } from "screens/Releases";

let stripePromise;

const App = () => {
  const { data: branding } = BrandingContextHook();

  useEffect(() => {
    if (branding && branding?.stipePublishableKey) {
      stripePromise = loadStripe(branding?.stipePublishableKey);
    }
  }, [branding]);
  return branding?.stipePublishableKey && stripePromise ? (
    <Elements stripe={stripePromise}>
      <AuthProvider>
        <Router>
          <Navbar />
          <div className="body">
            <Switch>
              <Route path="/" exact component={Releases} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/express-checkout" component={ExpressCheckout} />
              <Route path="/register" component={Register} />
              <Route
                path="/register-activation"
                component={RegisterActivation}
              />
              <Route path="/terms-of-use" component={TermsOfUse} />
              <ProtectedRoute path="/app">
                <AppScreen />
              </ProtectedRoute>
              <Route path="*">
                <Redirect to="/app" />
              </Route>
            </Switch>
          </div>
          <Footer />
        </Router>
        <ToastContainer />
      </AuthProvider>
    </Elements>
  ) : null;
};

export default App;
