import React, { useState } from "react";
import styled from "styled-components";
import { Images } from "img";
import {
  Qty,
  Description,
  BackButton,
  MediaAction,
  Title,
  MediaType,
  MediaTypeIcon,
  DetailSection,
  PageContainer,
  Credits,
  PosterImage,
} from "./common";
import CommonUtility from "utility/common";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { IoIosLink } from "react-icons/io";
import Lightbox from "react-image-lightbox";

export const ImageCardDetails = ({
  data,
  allowedSell,
  allowedMarketplace,
  userId,
  cancelSellClick,
  sellClicked,
  copyToClipboard,
  hoverText,
  backClick,
  isOwner,
}) => {
  const [lightBox, setLightBox] = useState(false);
  const download = () => {
    if ((data?.attachments || []).length > 0) {
      const element = document.createElement("a");
      element.target = "_blank";
      element.setAttribute(
        "href",
        data?.attachments[0]?.url || Images.defaultPack
      );
      element.setAttribute("download", data?.name);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };
  // const handleReplace=(string,value,to)=>{
  //  const replaced=string.replace(value,to)
  //  console.log(string,"=-======>repca")
  //  return replaced
  // }
  return (
    <PageContainer>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <DetailSection>
              <div className="row">
                <div className="col-lg-4 order-2 order-lg-1">
                  <PosterImage>
                    <img
                      src={data?.attachments[0]?.url || Images.defaultPack}
                      alt={data?.name}
                      onClick={() => setLightBox(true)}
                    />
                  </PosterImage>
                </div>
                <div className="col-lg-6 order-3 order-lg-2">
                  <MediaTypeIcon>
                    <img src={Images.assetType.image} alt="" />
                  </MediaTypeIcon>
                  <MediaType>Digital signed poster</MediaType>
                  <Title>{data?.name}</Title>
                  <Qty>
                    #{data?.groupSequence} of {data?.maxExisting}
                  </Qty>
                  <Description>{data?.text}</Description>
                  {data?.forSale && (
                    <Credits>
                      Selling Price:{" "}
                      {CommonUtility.currencyFormat(data?.sellPrice / 100)}
                    </Credits>
                  )}

                  <MediaAction>
                    <button
                      type="button"
                      className="btn btn-custom-form btn-block btn-lg"
                      onClick={download}
                    >
                      print/export
                    </button>

                    {data?.forSale && (
                      <>
                        {data?.owner?.id === userId ? (
                          <button
                            type="button"
                            className="btn btn-custom-form btn-block btn-lg"
                            onClick={cancelSellClick}
                          >
                            Cancel Sell
                          </button>
                        ) : (
                          <>
                            {allowedMarketplace && (
                              <Link to={`/app/payment?id=${data.id}&type=card`}>
                                <button className="btn btn-custom-form btn-block btn-lg">
                                  Buy
                                </button>
                              </Link>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {allowedSell && !data?.forSale && isOwner() && (
                      <button
                        type="button"
                        onClick={sellClicked}
                        className="btn btn-custom-form btn-block btn-lg"
                      >
                        SELL
                      </button>
                    )}

                    <button
                      className="btn btn-custom-form btn-block btn-lg copy-button"
                      id="nft"
                      onClick={copyToClipboard}
                    >
                      <IoIosLink />
                      <p className="d-lg-none mt-0 mb-0 ml-2"> {hoverText}</p>
                    </button>
                    <UncontrolledTooltip placement={"auto"} target="nft">
                      {hoverText}
                    </UncontrolledTooltip>
                  </MediaAction>
                </div>
                <div className="col-lg-2 order-1 order-lg-3 d-flex justify-content-lg-end align-items-lg-start">
                  <BackButton onClick={backClick}>Back</BackButton>
                </div>
              </div>
            </DetailSection>
          </div>
        </div>
      </div>
      {lightBox && (
        <Lightbox
          mainSrc={data?.attachments[0]?.url || Images.defaultPack}
          onCloseRequest={() => setLightBox(false)}
        />
      )}
    </PageContainer>
  );
};
