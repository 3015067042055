import React from "react";
import styled from "styled-components";
import { Images } from "img";
import {
  Qty,
  Description,
  BackButton,
  MediaAction,
  Title,
  MediaType,
  MediaTypeIcon,
  DetailSection,
  PageContainer,
  Credits,
  PosterImage,
} from "./common";
import { IoIosLink } from "react-icons/io";
import { UncontrolledTooltip } from "reactstrap";
import CommonUtility from "utility/common";
import { Link } from "react-router-dom";

const LastColumnStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;

const Characters = styled.div`
  display: inline-block;
  margin-top: 7rem;
  text-align: right;
`;

const CharactersTitle = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2.5;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 1.25rem;
  border-bottom: 1.5px solid #84c142;
  text-align: right;
`;

const CharacterName = styled.div`
  display: block;
  padding: 0.375rem 0;
  text-align: right;

  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #ffffff;

  &.active {
    font-weight: bold;
    color: #84c142;
  }
`;

export const TextCardDetails = ({
  data,
  allowedSell,
  allowedMarketplace,
  userId,
  cancelSellClick,
  sellClicked,
  copyToClipboard,
  hoverText,
  backClick,
  isOwner,
}) => (
  <PageContainer>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <DetailSection>
            <div className="row">
              <div className="col-lg-4 order-2 order-lg-1">
                <PosterImage>
                  <img
                    src={data?.icon?.url || Images.defaultPack}
                    alt={data?.name || "Card Image"}
                  />
                </PosterImage>

                {/* <DialougeContainer>
                                        <Dialouge>
                                            <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit."</p>
                                        </Dialouge>
                                        <DialougeAuthor>- Finley Hart</DialougeAuthor>
                                    </DialougeContainer> */}
              </div>
              <div className="col-lg-5 order-3 order-lg-2">
                <MediaTypeIcon>
                  <img src={Images.assetType.text} alt="" />
                </MediaTypeIcon>
                <MediaType>Character Bio</MediaType>
                <Title>{data?.name}</Title>
                <Qty>
                  #{data?.groupSequence} of {data?.maxExisting}
                </Qty>

                <Description>{data?.text}</Description>
                {data?.forSale && (
                  <Credits>
                    Selling Price:{" "}
                    {CommonUtility.currencyFormat(data?.sellPrice / 100)}
                  </Credits>
                )}
                <MediaAction>
                  <button
                    type="submit"
                    className="btn btn-custom-form btn-block btn-lg"
                  >
                    view film
                  </button>

                  {data?.forSale && (
                    <>
                      {data?.owner?.id === userId ? (
                        <button
                          className="btn btn-custom-form btn-block btn-lg"
                          onClick={cancelSellClick}
                        >
                          Cancel Sell
                        </button>
                      ) : (
                        <>
                          {allowedMarketplace && (
                            <Link to={`/app/payment?id=${data.id}&type=card`}>
                              <button className="btn btn-custom-form btn-block btn-lg">
                                Buy
                              </button>
                            </Link>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {allowedSell && !data?.forSale && isOwner() && (
                    <button
                      type="button"
                      onClick={sellClicked}
                      className="btn btn-custom-form btn-block btn-lg"
                    >
                      SELL
                    </button>
                  )}

                  <button
                    className="btn btn-custom-form btn-block btn-lg copy-button"
                    id="nft"
                    onClick={copyToClipboard}
                  >
                    <IoIosLink />
                    <p className="d-lg-none mt-0 mb-0 ml-2"> {hoverText}</p>
                  </button>
                  <UncontrolledTooltip placement={"auto"} target="nft">
                    {hoverText}
                  </UncontrolledTooltip>
                </MediaAction>
              </div>
              <div className="col-lg-3 order-1 order-lg-3">
                <LastColumnStyle>
                  <BackButton>Back</BackButton>
                  <Characters>
                    <CharactersTitle>characters</CharactersTitle>
                    <CharacterName className="active">
                      Finley Hart
                    </CharacterName>
                    <CharacterName>Sam Hart</CharacterName>
                    <CharacterName>Riku</CharacterName>
                    <CharacterName>Trevor</CharacterName>
                    <CharacterName>Veronica</CharacterName>
                    <CharacterName>Hakan</CharacterName>
                    <CharacterName>Katherine</CharacterName>
                    <CharacterName>Rebecca</CharacterName>
                    <CharacterName>Omar</CharacterName>
                  </Characters>
                </LastColumnStyle>
              </div>
            </div>
          </DetailSection>
        </div>
      </div>
    </div>
  </PageContainer>
);
