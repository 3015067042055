import React from 'react'
import { GoogleLogin } from 'react-google-login'
import { Images } from 'img'

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const GoogleBtn = ({ text,onSuccess }) => {

  return (
    <GoogleLogin
      clientId={googleClientId}
      buttonText={text}
      onSuccess={onSuccess}
      cookiePolicy={'single_host_origin'}
      responseType='code,token'
      render={renderprops => (
        <button id='loginwithGoogle' onClick={renderprops.onClick}>
          <img
            width='22px'
            className='icon'
            height='22px'
            src={Images.googleIcon}
            alt='google login button'
          />
          {text}
        </button>
      )}
    />
  )
}

export default GoogleBtn
