import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.85);
    flex-direction : column;
    color : #fff;
    font-weight: bold;
    font-size : 1.2rem;
    z-index: 1000;

    .loading-container{
        padding : 50px;
        .spinner > div{
            background-color : white !important;
        }
    }
`

export const LoadingBar = ({ dark }) => (
    <div className="row">
        <div className="col-12">
            <div className="loading-container">
                <div className={`spinner ${dark ? "dark" : "light"}`}>
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
        </div>
    </div>
)

export const OverlayLoadingBar = ({ dark, title }) => (
    <Container>
        <LoadingBar dark={dark} />
        <div className="row justify-content-center">
            <div className="col-10">
                <p className='text-center'>{title}</p>
            </div>
        </div>
    </Container>
)