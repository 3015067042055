import React,{ useState } from 'react'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PaymentService } from 'utility/services';
import { TextField } from 'elements/Inputs';
import { ErrorMessages } from 'utility/constants/common';
import { Modal,ModalBody,ModalHeader } from 'reactstrap';
import { IoLogoUsd } from 'react-icons/io'

const cashoutSchema = yup.object().shape({
    amount: yup.number().default(0).required("Amount is required").positive("Amount should be positive number"),
});

const USDIcon = {
    position: 'absolute',
    right: 0,
    top: '32px'
}

export const CashoutPopup = ({ showModal,toggle,cashoutSucceed }) => {

    const {
        register: cashout,
        errors,
        handleSubmit
    } = useForm({
        resolver: yupResolver(cashoutSchema),
        defaultValues: {
            amount: 0
        }
    });
    const [saving,setSaving] = useState(false);
    const [error,setError] = useState('');

    const submit = async (data) => {
        try {
            setError('');
            setSaving(true);
            await PaymentService.cashout(data.amount);
            cashoutSucceed(data.amount)
        } catch (err) {
            setError(err.error.title || ErrorMessages.default);
        } finally {
            setSaving(false);
        }
    }

    return (
        <Modal isOpen={showModal} size={"mini"} toggle={toggle} className="modal-dialog-centered green-modal"   >

            <div className="row">
                <div className="col-12">
                    <ModalHeader>
                        Cashout
                    </ModalHeader>
                    <ModalBody>
                        <form onSubmit={handleSubmit(submit)}>
                            <div className='input-group'>
                                <label>Amount(USD)</label>
                                <TextField
                                    type="number"
                                    ref={cashout}
                                    errors={errors?.amount}
                                    placeholder="Amount"
                                    name="amount"
                                />
                                <div style={USDIcon} className="show-password-toggle">
                                    <span
                                        className='input-group-text'
                                    >
                                        <IoLogoUsd />
                                    </span>
                                </div>
                            </div>
                            {error && <label className='text-danger mt-2'>
                                {error}
                            </label>}
                            <div className="d-flex mt-4">
                                <button type='submit' className='btn btn-next w-100 mr-2'>
                                    {saving ? 'Saving...' : 'Submit'}
                                </button>
                                <button type='button' className='btn btn-next w-100' onClick={toggle}>
                                    Cancel
                                </button>
                            </div>

                        </form>
                    </ModalBody>
                </div>
            </div>
        </Modal>
    )
};
