import React from "react";
import { Images } from "img";
import {
  Credits,
  Qty,
  Description,
  BackButton,
  MediaAction,
  Title,
  MediaType,
  MediaTypeIcon,
  DetailSection,
  PageContainer,
  PosterImage,
} from "./common";
import CommonUtility from "utility/common";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { IoIosLink } from "react-icons/io";

export const StreamCardDetails = ({
  data,
  allowedSell,
  allowedMarketplace,
  userId,
  cancelSellClick,
  sellClicked,
  copyToClipboard,
  hoverText,
  backClick,
  isOwner,
}) => (
  <PageContainer>
    <div
      className="StreamContainer"
      style={{
        backgroundImage: `linear-gradient(250.84deg, rgba(34,34,34, 0.5) -14.04%, rgba(0,0,0,0.5) 109.3%), url(${
          data?.icon?.url || Images.defaultPack
        })`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <DetailSection>
              <div className="row">
                {/* <div className="col-lg-4 order-2 order-lg-1">
                  <PosterImage>
                    <img
                      src={data?.icon?.url || Images.defaultPack}
                      alt={data?.name}
                    />
                  </PosterImage>
                </div> */}
                <div className="col-lg-6 order-3 order-lg-2">
                  <MediaTypeIcon>
                    <img src={Images.assetType.stream} alt="" />
                  </MediaTypeIcon>
                  <MediaType>FEATURE length FILM</MediaType>
                  <Title>{data?.name}</Title>
                  <Qty>
                    #{data?.groupSequence} of {data?.maxExisting}
                  </Qty>
                  <Description>{data?.text}</Description>
                  {/* <div dangerouslySetInnerHTML={{ __html: data?.text }}></div> */}

                  {data?.forSale && (
                    <Credits>
                      Selling Price:{" "}
                      {CommonUtility.currencyFormat(data?.sellPrice / 100)}
                    </Credits>
                  )}

                  <MediaAction>
                    <Link
                      to={`/app/play/${data?.id}`}
                      className="btn btn-custom-form btn-block btn-lg"
                    >
                      view film
                    </Link>

                    {data?.forSale && (
                      <>
                        {data?.owner?.id === userId ? (
                          <button
                            type="button"
                            className="btn btn-custom-form btn-block btn-lg"
                            onClick={cancelSellClick}
                          >
                            Cancel Sell
                          </button>
                        ) : (
                          <>
                            {allowedMarketplace && (
                              <Link to={`/app/payment?id=${data.id}&type=card`}>
                                <button className="btn btn-custom-form btn-block btn-lg">
                                  Buy
                                </button>
                              </Link>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {allowedSell && !data?.forSale && isOwner() && (
                      <button
                        type="button"
                        onClick={sellClicked}
                        className="btn btn-custom-form btn-block btn-lg"
                      >
                        SELL
                      </button>
                    )}

                    <button
                      className="btn btn-custom-form btn-block btn-lg copy-button"
                      id="nft"
                      onClick={copyToClipboard}
                    >
                      <IoIosLink />
                      <p className="d-lg-none mt-0 mb-0 ml-2"> {hoverText}</p>
                    </button>
                    <UncontrolledTooltip placement={"auto"} target="nft">
                      {hoverText}
                    </UncontrolledTooltip>
                  </MediaAction>
                </div>
                <div className="col-lg-2 offset-md-4 order-1 order-lg-3 d-flex justify-content-lg-end align-items-lg-start">
                  <BackButton onClick={backClick}>Back</BackButton>
                </div>
              </div>
            </DetailSection>
          </div>
        </div>
      </div>
    </div>
  </PageContainer>
);
