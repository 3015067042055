import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CommonUtility from "utility/common";
import { MarketplaceHook } from "hooks/marketplace";
import { LoadingBar } from "components/LoadingBar";
import { ErrorMessageBox } from "components/ErrorMessageBox";
import { AuthHook } from "context/auth";
import { MarketplaceCountHook } from "hooks/marketplace";
import { Images } from "img";
import angle from "img/angle_down.svg";
import angleUp from "img/angle-up.svg";
import styled from "styled-components";
import Filters from "components/Filters";
import { ReactSVG } from "react-svg";
import { useInView } from "react-intersection-observer";

const MyCardsPage = styled.section`
  position: relative;
  margin: 0;
`;

const PageHeader = styled.div`
  position: relative;
  padding: 5rem 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${Images.home});
    background-size: 100%;
    background-position: center center;
    z-index: -1;
    opacity: 0.65;
  }
`;

const HeaderTitle = styled.h1`
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-transform: uppercase;
`;

const CardsContainer = styled.div`
  position: relative;
  // margin: 6.75rem 0;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 575.98px) {
    justify-content: center;
  }
`;

const Card = styled.div`
  position: relative;
  background: #1a1a1a;

  padding: 1.75rem;
  margin: 1.3125rem;
  flex: 0 0 21.2%;

  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.55;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1199.98px) {
    flex: 0 0 28.8%;
  }
  @media (max-width: 991.98px) {
    flex: 0 0 41.7%;
  }
  @media (max-width: 767.98px) {
    flex: 0 0 43.7%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 575.98px) {
    flex: 0 0 80%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const CardMedia = styled.div`
  position: relative;
  padding: 0 0 44px 0;

  img {
    max-width: 100%;
  }
`;

const MediaType = styled.div`
  position: absolute;
  background: #1a1a1a;
  border-radius: 100%;
  width: 92px;
  height: 92px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
`;

const MediaTitle = styled.h2`
  font-size: 1.125rem;
  line-height: 1.55;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;

  margin-bottom: 1.5rem;
`;

const MediaQty = styled.div`
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.55;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;

  margin-bottom: 1.5rem;
`;

const ListItem = ({ item, isOwner }) => (
  <Card>
    <CardMedia>
      <img src={item.icon?.url || Images.defaultPack} alt={item.name} />
      {/* <MediaType>
        <img
          src={CommonUtility.getCardAssetImage(item.assetType)}
          alt={item.assetType}
        />
      </MediaType> */}
    </CardMedia>
    <MediaTitle>{item.name}</MediaTitle>
    <MediaQty>
      #{item.groupSequence} of {item.maxExisting}
    </MediaQty>
    <div>
      <Link to={`/app/nft-details/${item.id}`}>
        <button type="submit" className="btn btn-custom-form btn-block btn-lg">
          {isOwner
            ? "Details"
            : `Buy @${CommonUtility.currencyFormat(item.sellPrice / 100)}`}
        </button>
      </Link>
    </div>
  </Card>
);
const FilterButtons = (
  text,
  value,
  index,
  activeButton,
  setFilteredData,
  data,
  setActiveButton
) => (
  <div
    className="mb1-small col-sm-6 col-6 col-lg-3 mb-3"
    key={`${text}-${index}`}
  >
    <button
      className={`${
        activeButton === value.toLowerCase() ? "bgblue" : ""
      } filter-button`}
      onClick={() => {
        setActiveButton(value.toLowerCase());
        if (value.toLowerCase() === "all") {
          setFilteredData(data);
        } else {
          const cardFilter = data.filter(
            (item) => item.assetType.toLowerCase() === value.toLowerCase()
          );
          setFilteredData(cardFilter);
        }
      }}
    >
      {text}
    </button>
  </div>
);
export const Marketplace = () => {
  const [activeButton, setActiveButton] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const [scrollVal, setScrollVal] = useState(false);
  const containerFooter = useInView({ threshold: 0 });
  const containerHeader = useInView({ threshold: 0 });

  // const handleDuplicate = () => {
  //   const arr = filteredData;
  //   const uniqueIds = [];
  //   let uniqueCards = [];
  //   const unique = arr.filter((element) => {
  //     const isDuplicate = uniqueIds.includes(element.name);
  //     if (!isDuplicate) {
  //       uniqueIds.push(element?.name);
  //       uniqueCards.push(element);

  //       return setFilteredData(uniqueCards);
  //     }
  //   });
  // };
  // const handleAll = () => {
  //   if (activeButton.toLowerCase() === "all") {
  //     setFilteredData(data);
  //   } else {
  //     const cardFilter = data.filter(
  //       (item) => item.assetType.toLowerCase() === activeButton.toLowerCase()
  //     );
  //     setFilteredData(cardFilter);
  //   }
  // };
  const filterButtons = [
    { value: "All", label: "All" },
    { value: "Video", label: "Character" },
    { value: "Image", label: "Image" },
    { value: "Stream", label: "Film" },
  ];
  const { data, loading, hasMore, pageChanged } = MarketplaceHook();
  const { count } = MarketplaceCountHook();
  const { user } = AuthHook();

  useEffect(() => {
    setFilteredData(data);
  }, [data]);
  useEffect(()=>{
    if(containerFooter?.inView){
      setScrollVal(true)
    }
  },[containerFooter?.inView])

  useEffect(()=>{
    if(containerHeader.inView){
      setScrollVal(false)
    }
  },[containerHeader.inView])



 

  return (
    <MyCardsPage >
      <div className="payment">
        <div className="container-fluid px-0">
          <div className="row no-gutters">
            <div className="col-12">
              <PageHeader>
                <HeaderTitle>Marketplace</HeaderTitle>
              </PageHeader>
            </div>
          </div>
        </div>
      <div ref={containerHeader.ref}></div>
        <div className="container">
          <div className="row my-4 mb-5 ">
            <div>
              {count && (
                <h1 className="text-white h3">
                  Total Number of Items :
                  <span className="text-white"> {count}</span>
                </h1>
              )}
            </div>
            {/* <div className="Filter ml-auto ">
              <h1>Visibility</h1>
              <div className="btns">
                <button onClick={handleAll}>All</button>
                <button onClick={handleDuplicate}>Hide</button>
              </div>
            </div> */}
          </div>

          <div className="row my-4 mb-5">
            {filterButtons.map((item, index) =>
              FilterButtons(
                item.label,
                item?.value,
                index,
                activeButton,
                setFilteredData,
                data,
                setActiveButton
              )
            )}
          </div>
          <Filters
            activeButton={activeButton}
            setFilteredData={setFilteredData}
            allData={data}
          />
          <div className="row">
            <div className="col-12">
              <CardsContainer>
                {loading ? (
                  <LoadingBar dark={true} />
                ) : filteredData.length > 0 ? (
                  <>
                    {filteredData.map((item) => (
                      <ListItem
                        key={item.id}
                        item={item}
                        isOwner={item.owner?.id === user?.id}
                      />
                    ))}
                   
                  </>
                ) : (
                  <ErrorMessageBox title="No NFTS" dark={true}>
                    <p>No NFTS for selling</p>
                  </ErrorMessageBox>
                )}
               
              </CardsContainer>
              {hasMore && (
                      <button
                        disabled={!hasMore}
                        onClick={pageChanged}
                        className="btn btn-custom-form btn-block w-half"
                      >
                        Load More
                      </button>
                    )}
            </div>
          </div>
        </div>
      </div>
      <div className="seemore_btn">
        <button>
          {scrollVal? 'Scroll Up' : 'Scroll Down'}
          {scrollVal?  <ReactSVG src={angleUp} /> :  <ReactSVG src={angle} />}
        </button>
      </div>
      <div ref={containerFooter.ref}></div>
    </MyCardsPage>
  );
};
