import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Images } from "img";
import { LoadingBar } from "components/LoadingBar";
import CommonUtility from "utility/common";
import { PaymentType } from "utility/constants/common";
import { ErrorMessageBox } from "components/ErrorMessageBox";
import { PackTemplatesHook } from "hooks/pack-templates";
import { PageHeader } from "components/PageHeader";
import arraw1 from "img/arrow-up-solid.svg";
import arraw2 from "img/arrow-down-solid.svg";
import angle from "img/angle_down.svg";
import angleUp from "img/angle-up.svg";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import moment from "moment";
import { useInView } from "react-intersection-observer";
import { UserService } from "utility/services";

const ReleasesPage = styled.section`
  position: relative;
  margin: 0;
`;

const CardContainer = styled.div`
  position: relative;
  border-bottom: 1.5px solid #84c142;
  padding: 8rem 0;

  &:last-child {
    border-bottom: none;
  }
`;

const CardImageContainer = styled.div`
  position: relative;
  // background: #F4B459;
  padding: 4px;

  .card-type {
    position: absolute;
    height: 30px;
    top: -30px;
    left: 0px;
    // background: #F4B459;
    padding: 5px 8px 0;
    font-size: 1.0625rem;
    line-height: 1.5;
    letter-spacing: 0.1em;
    font-weight: bold;
    text-transform: uppercase;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      background: #f4b459;
      transform: skewX(20deg);
      right: -6px;
      top: 0;
      bottom: 0;
      width: 50%;
    }
  }

  &.common {
    // background: #C6E29A;

    .card-type {
      background: #c6e29a;

      &::after {
        background: #c6e29a;
      }
    }
  }

  img {
    max-width: 100%;
    width: 100%;
  }
`;

const CardTitle = styled.h2`
  font-weight: bold;
  font-size: 2.1875rem;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;

  margin-bottom: 1.5rem;
`;
const TwoColStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;
const CardQty = styled.div`
  font-style: italic;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 1.12;
  letter-spacing: 0.1em;
  color: #ffffff;
  text-align: left;
`;
const CardPrice = styled.div`
  font-weight: bold;
  font-size: 1.5625rem;
  line-height: 1.12;
  letter-spacing: 0.1em;
  color: #ffffff;
  text-align: right;
`;
const CardDescription = styled.div`
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 1.5rem;
  white-space: pre-line;

  ul {
    padding: 0;
    margin: 5px 0 0 0;
    padding-left: 20px;
  }
`;
const ActionButton = styled.div`
  width: 100%;
  max-width: 245px;
  position: relative;
`;
const Filters = ({ setFilteredData, allData }) => {
  function sortingByPrice(value) {
    let copyData = [...allData];
    if (value === 1) {
      copyData.sort(function (a, b) {
        return a.price - b.price;
      });
      return copyData;
    } else {
      copyData.sort(function (a, b) {
        return b.price - a.price;
      });
      return copyData;
    }
  }

  function sortingByDate(value) {
    let copyData = [...allData];
    if (value === 1) {
      copyData.sort(
        (a, b) =>
          new moment(a.releaseDate || "2000-12-26").format("YYYYMMDD") -
          new moment(b.releaseDate || "2000-12-26").format("YYYYMMDD")
      );

      return copyData;
    } else {
      copyData.sort(
        (a, b) =>
          new moment(b.releaseDate || "2000-12-26").format("YYYYMMDD") -
          new moment(a.releaseDate || "2000-12-26").format("YYYYMMDD")
      );

      return copyData;
    }
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="filters">
          <div className="Filter">
            <h1>Sort by Price</h1>
            <div className="btns">
              <button onClick={() => setFilteredData(sortingByPrice(0))}>
                <img src={arraw1} />
              </button>
              <button onClick={() => setFilteredData(sortingByPrice(1))}>
                <img src={arraw2} />
              </button>
            </div>
          </div>
          <div className="Filter">
            <h1>Sort by Date</h1>
            <div className="btns">
              <button onClick={() => setFilteredData(sortingByDate(0))}>
                <img src={arraw1} />
              </button>
              <button onClick={() => setFilteredData(sortingByDate(1))}>
                <img src={arraw2} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Item = ({ item, handleBuy }) => (
  <CardContainer>
    <div className="row">
      <div className="col-lg-4 offset-lg-1">
        <CardImageContainer className={(item?.rarityClass || "").toLowerCase()}>
          {/* <div className='card-type'>{(item?.rarityClass || '').toLowerCase()}</div> */}
          <img src={item?.picture?.url || Images.defaultPack} alt="" />
        </CardImageContainer>
      </div>
      <div className="col-lg-5 offset-lg-1 d-flex flex-column justify-content-lg-between">
        <div>
          <CardTitle>{item.name}</CardTitle>
          <TwoColStyle>
            {(item.quantity || 0) > 0 && (
              <CardQty>{item.quantity} remaining</CardQty>
            )}
            <CardPrice>
              {CommonUtility.currencyFormat(item.price / 100)}
            </CardPrice>
          </TwoColStyle>
          <CardDescription>{item?.description}</CardDescription>
        </div>
        <ActionButton>
          {(item.quantity || 0) > 0 ? (
            <button
              onClick={() => handleBuy(item.id)}
              className="btn btn-custom-form btn-block btn-lg"
            >
              BUY
            </button>
          ) : (item.quantity || 0) === -21 ? (
            <button disabled className="btn btn-custom-form btn-block btn-lg">
              Coming Soon
            </button>
          ) : (
            <button disabled className="btn btn-custom-form btn-block btn-lg">
              Sold Out
            </button>
          )}
        </ActionButton>
      </div>
    </div>
  </CardContainer>
);

export const Releases = () => {
  const { data, loading } = PackTemplatesHook();
  const [filteredData, setFilteredData] = useState([]);
  const [scrollVal, setScrollVal] = useState(false);
  const containerFooter = useInView({ threshold: 0 });
  const containerHeader = useInView({ threshold: 0 });
  useEffect(() => {
    setFilteredData(data);
  }, [data]);
  const history = useHistory();
  const handleBuy = (id) => {
    if (UserService.isAuthenticated())
      return history.push(`/app/payment?id=${id}&type=${PaymentType.pack}`);
    return history.push(`/express-checkout?id=${id}&type=${PaymentType.pack}`);
  };
  useEffect(() => {
    if (containerFooter?.inView) {
      setScrollVal(true);
    }
  }, [containerFooter?.inView]);

  useEffect(() => {
    if (containerHeader.inView) {
      setScrollVal(false);
    }
  }, [containerHeader.inView]);

  return (
    <ReleasesPage>
      <div className="payment">
        <PageHeader title="Releases" />
        <div className="container">
          <div ref={containerHeader.ref}></div>
          <div className="mt-3">
            <Filters allData={data} setFilteredData={setFilteredData} />
          </div>
          <div className="row">
            <div className="col-12">
              {loading && <LoadingBar />}
              {filteredData.map((item) => (
                <Item handleBuy={handleBuy} item={item} key={item.id} />
              ))}

              {data.length === 0 && !loading && (
                <div className="my-5">
                  {" "}
                  <ErrorMessageBox>
                    <h3 className="text-center text-white">
                      Comming Soon, stay tuned
                    </h3>
                  </ErrorMessageBox>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="seemore_btn">
        <button>
          {scrollVal ? "Scroll Up" : "Scroll Down"}
          {scrollVal ? <ReactSVG src={angleUp} /> : <ReactSVG src={angle} />}
        </button>
      </div>
      <div ref={containerFooter.ref}></div>
    </ReleasesPage>
  );
};
