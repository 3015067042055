import React, { useState } from 'react'
import Document from 'layout/Document'
import { Link, useHistory } from 'react-router-dom'
import GoogleButton from 'components/GoogleButton'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { Images } from 'img';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from 'elements/Inputs'
import { UserService } from 'utility/services'
import { AuthHook } from 'context/auth'
import { ErrorMessages } from 'utility/constants/common'
import { TermsAcceptPopup } from 'components/TermsAcceptPopup'
import { BrandingContextHook } from 'context/branding'
import ReCAPTCHA from "react-google-recaptcha";
const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KET;

const loginSchema = yup.object().shape({
	password: yup.string().required("Password is required"),
	username: yup.string().required("Email is required"),
	rememberMe: yup.boolean()
});

const Login = () => {

	const {
		register: login,
		errors,
		handleSubmit,
	} = useForm({
		resolver: yupResolver(loginSchema)
	});

	const { login: loginContext } = AuthHook();
	const { data: branding } = BrandingContextHook();

	const history = useHistory();

	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [googleToken, setGoogleToken] = useState('');
	const [showAcceptTerm, setShowAcceptTerm] = useState(false);
	const [captchaKey, setCaptchaKey] = useState('');

	const afterLogin = (result) => {
		UserService.storeToken(result.id_token);

		setTimeout(async () => {
			const user = await UserService.get();
			UserService.storeUser(user);
			loginContext(user);
			history.push('/')
		});
	}

	const googleLogin = async (data) => {
		try {
			setLoading(true);
			const result = await UserService.login({
				googleIdToken: googleToken
			});
			afterLogin(result);
		} catch (err) {
			console.log(err);
			setError(ErrorMessages.default);
		} finally {
			setLoading(false);
		}
	}

	const googleLoginInit = (data) => {
		setGoogleToken(data.tokenId);
		setShowAcceptTerm(true);
	}

	const onSubmit = async (data) => {
		try {
			setLoading(true);
			const req = {
				...data
			}
			if (branding?.reCaptchaEnabled) {
				req.reCaptchaToken = captchaKey
			}
			const result = await UserService.login(req);
			afterLogin(result);
		} catch (err) {
			const error = (err?.error?.detail === 'Bad credentials') ? ErrorMessages.badCredentials : err?.error?.detail
			setError(error || ErrorMessages.badCredentials);
		} finally {
			setLoading(false);
		}
	}

	return (
		<Document title='Login' >
			<div>
				<div
					className="BackgoundContainer"
					style={{
						backgroundImage: `url(${Images.home})`
					}} />

				<div className="login-container">
					<div className='container'>

						<div className='row'>

							<div className='col-lg-6 no-float'>
								<div className='login-logo-container'>
									<img src={Images.logo.big} width='300' alt='Logo' />
								</div>
							</div>

							<div className='col-lg-6 no-float'>

								<div className="login-box">

									<h2 className='signin-textstyle'>Sign In</h2>

									<GoogleButton
										text='Sign In with Google'
										onSuccess={googleLoginInit}
									/>

									<div className='or-text '>or</div>

									<form name='formLogin' noValidate onSubmit={handleSubmit(onSubmit)}>

										<div className='mb-3'>
											<TextField
												ref={login}
												errors={errors?.username}
												placeholder="Email Address"
												name="username"
											/>
										</div>

										<div className='input-group'>
											<TextField
												type={showPassword ? 'text' : 'password'}
												ref={login}
												errors={errors?.password}
												placeholder="Password"
												name="password"
											/>

											<div className="show-password-toggle">
												<span
													className='input-group-text'
													onClick={e => {
														setShowPassword(!showPassword)
													}}
												>
													{showPassword ? <BsEye color="#d9efb6" /> : <BsEyeSlash color="#d9efb6" />}
												</span>
											</div>
										</div>

										{branding?.reCaptchaEnabled && <ReCAPTCHA
											sitekey={siteKey}
											onChange={(value) => setCaptchaKey(value)}
										/>}

										{error && <div className="text-danger my-3">
											{error}
										</div>}
										<div className='d-flex justify-content-between align-items-sm-center py-4 flex-column flex-sm-row'>
											<div className='agreement-text-style'>
												<label className='checkbox'>
													<input type='checkbox' name="rememberMe" ref={login} />
													<span className='default'></span>
												</label>
												Remember Me
											</div>

											{/* <Link to='/terms-of-use' target="_blank" className="forgot-password-style">Terms of Use</Link> */}
											<Link to='/forgot-password' className="forgot-password-style mt-3 mt-sm-0">Forgot Password?</Link>
										</div>

										<button
											type="submit"
											disabled={loading}
											className='btn btn-custom-form btn-block btn-lg'
										>
											{loading
												? 'Loading...'
												: 'Sign In'}
										</button>

									</form>

									<h4 className='register-text-style mt-4'>
										Don't have an account?{' '}
										<Link to='/register' className='linkDefalut'>
											Sign Up Now
										</Link>
									</h4>
									<TermsAcceptPopup
										showModal={showAcceptTerm}
										toggle={() => setShowAcceptTerm(false)}
										termAccepted={() => googleLogin()}
									/>
								</div>
							</div>
						</div>
					</div>
				</div >
			</div>
		</Document >
	)
}

export default Login
