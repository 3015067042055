import React, { forwardRef } from "react";
import 'react-phone-number-input/style.css'
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form"

export const TextField = forwardRef(({ name, placeholder, errors, ...rest }, ref) => (
    <>
        <input
            name={name}
            placeholder={placeholder}
            ref={ref}
            className={`textfield-1 ${errors?.message ? 'is-invalid' : ''}`}
            {...rest}
            autoComplete="off"

        />
        {errors?.message && (
            <div className='invalid-feedback'>
                {errors?.message}.
            </div>
        )}
    </>
))

export const MobileFormField = ({ control, name, placeholder, errors, ...rest }) => (
    <>
        <PhoneInputWithCountry
            name={name}
            placeholder={placeholder}
            control={control}
            limitMaxLength={true}
            className={`textfield-1 ${errors?.message ? 'is-invalid' : ''}`}
            {...rest}
        />
    </>
);