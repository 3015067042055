export const APIPath = {

    branding: 'branding',

    // User 
    login: 'authenticate',
    register: 'register',
    registerActivate: 'activate',
    forgotPassword: 'account/reset-password/init',
    resetPassword: 'account/reset-password/finish',
    changePassword: 'account/change-password',
    account: 'account',
    userHistory: 'card-transactions',

    // Cards
    cards: 'cards',
    featuredCard: 'cards/featured',
    myCards: 'cards/mine',
    buyCard: 'cards/market/buy',
    cardHistory: 'card-events/by-card',
    sellCard: 'cards/market/sell',
    canSell: 'cards/market/can-sell',
    bankSetup: 'cash-out-requests/login-link',

    // Packs
    packs: 'packs',
    buyPack: 'packs/buy/v2',
    myPacks: 'packs/mine',
    packTemplates: 'public/pack-templates',
    openPack: 'packs/open',
    packHasFeature: 'packs/has-featured',

    // Marketplace
    marketplace: 'cards/market',
marketplaceCount:'cards/market/count',
    cashout: 'payouts/cashout',
    cryptoCharge: 'crypto/charge',

    // Payment 
    addPayment: 'payment/add',
    savedCard: 'payment/get',

    video: 'video/otp'

}