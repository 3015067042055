import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthHook } from "context/auth";
import { UserService } from "utility/services";
import { TextField } from "elements/Inputs";
import { ErrorMessages } from "utility/constants/common";
import { useHistory } from "react-router-dom";

const passwordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .min(4, "Password should be between 4 to 16 characters")
    .max(16, "Password should be between 4 to 16 characters")
    .required("Current password is required"),
  newPassword: yup
    .string()
    .min(4, "Password should be between 4 to 16 characters")
    .max(16, "Password should be between 4 to 16 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("newPassword"), null],
      "New and Confirm Password must match"
    )
    .required(),
});

export const ChangePassword = () => {
  const history = useHistory();
  const {
    register: changePassword,
    errors,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });
  const [showPassword, setShowPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [profileImage, setProfileImage] = useState("");

  const { user, login } = AuthHook();

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [user]);
  useEffect(() => {
    setProfileImage(user?.imageUrl);
  }, [user]);
  const save = async (data) => {
    try {
      setError("");
      setSuccess("");
      setLoading(true);

      const requestBody = {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      };
      await UserService.changePassword(requestBody);
      login(requestBody);
      setSuccess("Password Updated successfully.");
    } catch (err) {
      setError(ErrorMessages.default || "Invalid Password");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="payment">
      <div className="header-background">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="popup">
                <button onClick={() => history.goBack()}>X</button>
              </div>
              <div className="popup-box">
                <form noValidate onSubmit={handleSubmit(save)}>
                  <label className="popup-title pb-4">Change Password</label>

                  <div className="form-group">
                    {user?.hasPassword && (
                      <div className="input-group mb-3">
                        <TextField
                          type={currentPassword ? "text" : "password"}
                          ref={changePassword}
                          placeholder="Enter Current Password"
                          name="currentPassword"
                          errors={errors?.currentPassword}
                        />
                        <div className="show-password-toggle">
                          <span
                            className="input-group-text"
                            onClick={(e) => {
                              setCurrentPassword(!currentPassword);
                            }}
                          >
                            {currentPassword ? (
                              <BsEye color="#d9efb6" />
                            ) : (
                              <BsEyeSlash color="#d9efb6" />
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <TextField
                        type={newPassword ? "text" : "password"}
                        ref={changePassword}
                        placeholder="Enter New Password"
                        name="newPassword"
                        errors={errors?.newPassword}
                      />
                      <div className="show-password-toggle">
                        <span
                          className="input-group-text"
                          onClick={(e) => {
                            setNewPassword(!newPassword);
                          }}
                        >
                          {newPassword ? (
                            <BsEye color="#d9efb6" />
                          ) : (
                            <BsEyeSlash color="#d9efb6" />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <TextField
                        type={showPassword ? "text" : "password"}
                        ref={changePassword}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        errors={errors?.confirmPassword}
                      />
                      <div className="show-password-toggle">
                        <span
                          className="input-group-text"
                          onClick={(e) => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <BsEye color="#d9efb6" />
                          ) : (
                            <BsEyeSlash color="#d9efb6" />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn btn-next w-100"
                    >
                      {loading ? "Saving..." : "SAVE"}
                    </button>
                  </div>

                  {success && <label className="text-white">{success}</label>}
                  {error && <label className="text-danger">{error}</label>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
