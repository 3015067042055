import { LoadingBar } from 'components/LoadingBar';
import React from 'react'
import styled from 'styled-components';
import DateUtility from 'utility/date';

const HistoryTableSection = styled.div`
    position: relative;
    margin: 3rem 0 5rem;
`

const TableTitle = styled.h2`
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.55;
    letter-spacing: 0.01em;
    color: #84C142;
    text-transform: uppercase;
    margin-bottom: 1rem;
`

const TableContainer = styled.div`
    position: relative;
    width: 100%;

    @media (max-width: 575.98px) { 
        overflow-x: auto;
    }
`

const HistoryTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-top: 1.5px solid #84C142;

    @media (max-width: 575.98px) { 
        width: 500px;
    }

    thead > tr {
        th:nth-child(1) {
            width: 30%;
            @media (max-width: 575.98px) {
                width: auto;
            }
        }
        th:nth-child(2) {
            width: 45%;
            @media (max-width: 575.98px) {
                width: auto;
            }
        }
        th:nth-child(3) {
            width: 25%;
            @media (max-width: 575.98px) {
                width: auto;
            }
        }
    }


    tr {
        border-bottom: 1.5px solid #84C142;
        border-collapse: collapse;
    }

    td, th {
        padding: 1.25rem 0.5rem;
        height: 65px;
    }

    th {
        font-style: normal;
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 1.55;
        letter-spacing: 0.01em;
        color: #D9EFB6;
    }

    td {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0.01em;
        color: #FFF;
        vertical-align: top;

    }
`

export const History = ({ data, loading }) => {

    return (
        <HistoryTableSection>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <TableTitle>History</TableTitle>
                        {loading && <LoadingBar />}
                        <TableContainer>
                            <HistoryTable>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Event</th>
                                        <th>User</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(item => (<tr key={item.id}>
                                        <td>
                                            {DateUtility.formatDate(item.createdDate)}
                                        </td>
                                        <td>
                                            {item.event}
                                        </td>
                                        {/* <td>{CommonUtility.currencyFormat(item.amount / 100)}</td> */}
                                        <td>{`${item.user?.userName || 'Anonymous'}`}</td>
                                    </tr>))}
                                </tbody>
                            </HistoryTable>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </HistoryTableSection>
    )
}
